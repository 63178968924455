import { differenceInHours } from "date-fns";

import { isFeatureEnabled } from "../feature-flags";
import { GeographyConfig, getGeographyConfig } from "../geographies";

export const isLateAppointmentChange = (
  appointment: { startAt: string } | null | undefined,
  options: { currentDate?: Date; geographyConfig?: GeographyConfig } = {},
  // eslint-disable-next-line max-params
): boolean => {
  const { currentDate = new Date(), geographyConfig = getGeographyConfig() } =
    options;

  return Boolean(
    isFeatureEnabled("lateAppointmentChanges", { geographyConfig }) &&
      appointment &&
      appointment.startAt &&
      differenceInHours(new Date(appointment.startAt), currentDate) < 24,
  );
};
