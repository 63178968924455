fragment MessageFields on Message {
  id
  type
  language
  title
  message
}
query GetAppointmentAndOfferList($fetchAppointmentsRequestId: UUID!) {
  globalMessages {
    ...MessageFields
  }
  # patientAppointments is a hack, see comment on app_public.patient_appointments
  appointments: patientAppointments(orderBy: [START_AT_ASC]) {
    bookingType {
      id
      maxRescheduleCount
      visibleName
      messages {
        ...MessageFields
      }
    }
    rescheduleCount
    id
    startAt: visibleStartAt
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    canCancel
    canReschedule
    careUnit {
      name
      address
      postCode
      postAddress
      id
      enabledFeatures(filter: { name: { in: [WAITLIST, WISHLIST] } }) {
        id
        name
      }
      messages {
        ...MessageFields
      }
    }
    caregiver {
      id
      name
    }
    onWaitlist
    onWishlist
    rescheduleAppointmentRequestsByAppointmentId(
      filter: { status: { in: [QUEUED, IN_PROGRESS] } }
    ) {
      id
      status
    }
    cancelAppointmentRequestsByAppointmentId(
      filter: { status: { in: [QUEUED, IN_PROGRESS] } }
    ) {
      id
      status
    }
  }
  offers(filter: { status: { in: [REVOKED, UNDECIDED] } }) {
    id
    status
  }
  fetchAppointmentsRequest(id: $fetchAppointmentsRequestId) {
    id
    status
    fetchStatus
  }
  referrals {
    id
  }
}
