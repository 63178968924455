import { RadioGroup as MuiRadioGroup, SxProps, Theme } from "@mui/material";
import { ChangeEvent, forwardRef, ReactNode } from "react";

type RadioGroupProperties = {
  children?: ReactNode;
  defaultValue?: unknown;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  sx?: SxProps<Theme>;
  value?: unknown;
};

export const RadioGroup = forwardRef<unknown, RadioGroupProperties>(
  // eslint-disable-next-line max-params
  function RadioGroup(
    { children, defaultValue, onChange, sx, value },
    reference,
  ) {
    return (
      <MuiRadioGroup
        defaultValue={defaultValue}
        onChange={onChange}
        ref={reference}
        row
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          ...sx,
        }}
        value={value}
      >
        {children}
      </MuiRadioGroup>
    );
  },
);
