import { Trans } from "@lingui/macro";
import { Grid, Typography } from "@mui/material";
import { useLocalStorageValue } from "@react-hookz/web";
import { useEffect, useRef } from "react";
import {
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import { isAppointmentWaitlistable } from "../../utils";
import { GetAppointmentAndOfferListQuery } from "../get-appointments-and-offers-list.graphql";
import { NoBookingMessage } from "../no-booking-message";
import { AppointmentComponent } from "./appointment";

function AppointmentsList({
  appointments,
  fetchStatus,
  fetchAppointmentsRequestId,
}: {
  appointments: NonNullable<GetAppointmentAndOfferListQuery["appointments"]>;
  fetchStatus:
    | Record<string, { status: boolean; count: number; careUnitName: string }>
    | null
    | undefined;
  fetchAppointmentsRequestId: string;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const firstAppointmentId = appointments?.[0]?.id ?? "";
  const {
    value: hasShownWaitlistDialogForFirstAppointment,
    set: setHasShownWaitlistDialog,
  } = useLocalStorageValue<Date>(
    `hasShownWaitlistDialog-${firstAppointmentId}`,
  );
  const showDialogTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  // Check if we're rendering because of a exact match in the url (i.e. do we have any opened dialogs?)
  // We should probably find a "better" way to figure this out or find a better solution in general
  // Perhaps this should be the last step in the onboarding or something.
  // Should the logic to show the offers question live in it's own component?
  const resolvedPath = useResolvedPath("/");
  const currentPageIsIndex = useMatch({
    path: resolvedPath.pathname,
    end: true,
  });

  const hasSingleAppointment = appointments?.length === 1;
  const isWaitlistable =
    appointments[0] && isAppointmentWaitlistable(appointments[0]);

  const shouldShowWaitlistDialog =
    currentPageIsIndex &&
    hasSingleAppointment &&
    !hasShownWaitlistDialogForFirstAppointment &&
    isWaitlistable;

  useEffect(() => {
    // TODO: Update this check to use the date in "hasShownWaitlistDialog"
    if (shouldShowWaitlistDialog) {
      showDialogTimeoutHandle.current = setTimeout(() => {
        setHasShownWaitlistDialog(new Date());
        navigate(`appointment/${appointments[0]?.id}/waitlist/ask`, {
          state: { backgroundLocation: location },
        });
      }, 1000);
    }

    return () => {
      if (showDialogTimeoutHandle.current) {
        clearTimeout(showDialogTimeoutHandle.current);
      }
    };
  }, [
    shouldShowWaitlistDialog,
    appointments,
    location,
    navigate,
    setHasShownWaitlistDialog,
  ]);

  return appointments?.length === 0 ? (
    <Grid item paddingTop={0.5}>
      <NoBookingMessage
        // with every fetch appointment request, render new instance of NoBookingMessage
        // It has uiState that depends on the value of the fetchStatus,
        // and if fetchStatus changes, it might not get recalculated in the NoBookingMessage.
        fetchStatus={fetchStatus}
        key={fetchAppointmentsRequestId}
      />
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="stretch"
      spacing={{
        xs: 2,
        sm: 2,
      }}
      sx={{
        paddingX: {
          xs: 2,
          sm: 3,
        },
        paddingY: {
          xs: 1,
          sm: 2,
        },
      }}
    >
      <Grid item>
        <Typography
          component="div"
          sx={{
            textTransform: "uppercase",
            textAlign: "center",
            fontWeight: 700,
            letterSpacing: "0.166em",
          }}
          variant="caption"
        >
          <Trans>My Appointments</Trans>
        </Typography>
      </Grid>

      {appointments?.map((appointment) => (
        <AppointmentComponent
          appointment={appointment}
          key={appointment.id}
          onClick={() => {
            if (showDialogTimeoutHandle.current) {
              clearTimeout(showDialogTimeoutHandle.current);
            }
          }}
        />
      ))}
      <Grid item>
        <Typography
          align="center"
          component="div"
          sx={{
            color: "rgba(34, 76, 79, 0.4)",
            textTransform: "none",
            fontWeight: 700,
          }}
          variant="caption"
        >
          <Trans>No more appointments down here...</Trans>
        </Typography>
      </Grid>
    </Grid>
  );
}

export { AppointmentsList };
