query GetAppointment($id: UUID!) {
  appointment: booking(id: $id) {
    id
    bookingType {
      id
      maxRescheduleCount
      cancellationReasonRequired
      wishlistEnabled
    }
    canReschedule
    rescheduleCount
    careUnit {
      enabledFeatures {
        id
        name
      }
      name
    }
    startAt: visibleStartAt
    startAtInCareUnitsTimezone: visisbleEndAtInCareUnitsTimezone
    onWishlist
    onWaitlist
    rescheduleAppointmentRequestsByAppointmentId {
      id
    }
  }
}
