import { useEffect, useState } from "react";

import { getGeographyConfig } from "../geographies";
import { userManager } from "../user-manager";
import { throttle } from "../utils";

const { signoutTimers } = getGeographyConfig();

/**
 * Adapted from {@link https://usehooks.com/useidle @uidotdev/usehooks}.
 */
function useIdle(milliseconds: number): boolean {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    if (!Number.isFinite(milliseconds)) {
      return;
    }

    let timeoutId: number;

    const handleTimeout = () => {
      setIsIdle(true);
    };

    const handleEvent = throttle(() => {
      setIsIdle(false);
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(handleTimeout, milliseconds);
    }, 500);

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleEvent();
      }
    };

    timeoutId = window.setTimeout(handleTimeout, milliseconds);

    window.addEventListener("mousemove", handleEvent);
    window.addEventListener("mousedown", handleEvent);
    window.addEventListener("resize", handleEvent);
    window.addEventListener("keydown", handleEvent);
    window.addEventListener("touchstart", handleEvent);
    window.addEventListener("wheel", handleEvent);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("mousemove", handleEvent);
      window.removeEventListener("mousedown", handleEvent);
      window.removeEventListener("resize", handleEvent);
      window.removeEventListener("keydown", handleEvent);
      window.removeEventListener("touchstart", handleEvent);
      window.removeEventListener("wheel", handleEvent);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.clearTimeout(timeoutId);
    };
  }, [milliseconds]);

  return isIdle;
}

export function useInactivitySignout(): void {
  const inactivityMilliseconds = signoutTimers?.inactivitySeconds
    ? signoutTimers.inactivitySeconds * 1000
    : Number.POSITIVE_INFINITY;
  const isIdle = useIdle(inactivityMilliseconds);

  useEffect(() => {
    if (isIdle) {
      userManager.signoutRedirect();
    }
  }, [isIdle]);
}
