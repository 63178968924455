import { Trans } from "@lingui/macro";
import { Box, Card, Grid, Typography } from "@mui/material";

import { colors } from "../../theme";
import { GetReferralsQuery } from "./get-referrals.graphql";

function Referral({
  referral,
}: {
  referral: NonNullable<GetReferralsQuery["referrals"]>[number];
}) {
  const bookingTypeName =
    referral.bookingType?.visibleName || referral.bookingType?.name;
  return (
    <Grid data-cy="referral-list-item" item key={referral.id}>
      <Card
        sx={{
          boxShadow: "0px 16px 16px -11px rgba(0, 0, 0, 0.05)",
          border: "2px solid #E3E3E3",
        }}
      >
        <Box
          sx={{
            padding: 2,
            background: colors.zymegoLightTravertine,
          }}
        >
          <Typography fontWeight={500} variant="body2">
            <Trans>
              Your referral has been received. Zymego regularly searches for
              available times at the clinic. You will receive an SMS when we
              have booked you in for a visit.
            </Trans>
          </Typography>
        </Box>
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography
            component="div"
            sx={{
              fontWeight: 500,
            }}
            variant="h3"
          >
            {referral.careUnit?.name}
          </Typography>
          <Typography
            component="div"
            sx={{
              fontWeight: 500,
              display: {
                xs: "none",
                sm: "block",
              },
            }}
            variant="body2"
          >
            <Trans>
              {bookingTypeName}
              {referral.caregiver?.name
                ? ` with
                ${referral.caregiver.name}`
                : undefined}
            </Trans>
          </Typography>
          <Typography
            component="div"
            sx={{
              fontWeight: 500,
              display: {
                xs: "none",
                sm: "block",
              },
            }}
            variant="body2"
          >
            {referral.careUnit?.address}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );
}
export { Referral };
