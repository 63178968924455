import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";

import { Layout } from "../layout";
import { colors } from "../theme";
import { AppUserState } from "../user/app-user";
import { AuthShield } from "./auth-shield";

function AccessDeniedView({ state }: { state?: AppUserState }) {
  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Typography
          component="div"
          sx={{
            color: colors.zymegoGranite,
            fontWeight: 700,
            letterSpacing: "0.166em",
            textAlign: "center",
            textTransform: "uppercase",
          }}
          variant="caption"
        >
          <Trans>Access denied</Trans>
        </Typography>
        <AuthShield requiredAuthLevel="low" state={state} />
      </Box>
    </Layout>
  );
}

export { AccessDeniedView };
