import { t, Trans } from "@lingui/macro";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { NhsContactInfoUpdateText } from "../nhs/contact-info-update-text";
import { getConfig } from "../runtime-config";
import { useUser } from "../user/user-context";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { OnboardingView } from "./onboarding-view";
import { useUpdatePatientEmailMutation } from "./update-patient-email.graphql";

interface FormValues {
  email: string;
}

const { AUTH_IDENTITY_PROVIDER } = getConfig();

export function EmailInfo() {
  const user = useUser();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  const [updatePatientEmail, { error: mutationError }] =
    useUpdatePatientEmailMutation();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    values: {
      email: patient?.email ?? user.profile.email ?? "",
    },
  });

  if (loading) {
    return null;
  }

  if (error || mutationError || !patient) {
    throw new Error(t`Something went wrong`);
  }

  return (
    <OnboardingView isValid={isValid} step="email">
      <OnboardingView.DescriptionText>
        <Trans>
          First we need your contact information to send booking confirmations.
        </Trans>
      </OnboardingView.DescriptionText>
      <OnboardingView.Stepper />
      <OnboardingView.Icon icon="envelope" />
      <OnboardingView.ActionText>
        <Trans>Enter your email address</Trans>
      </OnboardingView.ActionText>
      <OnboardingView.Form
        handleSubmit={handleSubmit}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onSubmit={async (values): Promise<void> => {
          await updatePatientEmail({
            variables: { id: patient.id, email: values.email },
          });
        }}
      >
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <TextField
              sx={{ direction: "ltr" }}
              {...field}
              InputLabelProps={{ shrink: true }}
              error={fieldState.invalid}
              fullWidth={true}
              helperText={fieldState.error?.message}
              label={t`Email`}
              size="medium"
              type="email"
            />
          )}
          rules={{
            required: t`Email is required.`,
            pattern: {
              value: /^.+@.+$/,
              message: t`Please provide a valid email address.`,
            },
          }}
        />
        {AUTH_IDENTITY_PROVIDER === "nhs" && (
          <NhsContactInfoUpdateText sx={{ marginBottom: 1.5, marginTop: 1 }} />
        )}
      </OnboardingView.Form>
    </OnboardingView>
  );
}
