import { keyframes } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ReactComponent as ZymegoLogo } from "./icons/zymego-logo.svg";
import { colors } from "./theme";

const LoadingWrapper = styled(Box)`
  text-align: center;
  padding: 20px;
`;

const LoadingAnimation = styled(Box)`
  padding: 20px 0;
`;

const bounceAnimation = keyframes`
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
`;

const LoadingAnimationDot = styled(Box)`
  border-radius: 100%;
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  transform: translateY(-50%);
  background-color: ${colors.zymegoOrange};
  animation: ${bounceAnimation} 1s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite;

  & {
    margin-right: 0.5625rem;
  }

  &:nth-of-type(1) {
    animation-delay: 0.15s;
  }

  &:nth-of-type(2) {
    animation-delay: 0.3s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.45s;
  }
`;

const LoadingText = styled(Box)`
  font-size: 0.8125rem;
  color: ${colors.zymegoDarkMint};
`;

function Loading({
  text = "Loading",
  logo = true,
}: {
  text: string | JSX.Element;
  logo?: boolean;
}) {
  return (
    <LoadingWrapper>
      {logo ? <ZymegoLogo style={{ fill: colors.zymegoGreen }} /> : null}
      <LoadingAnimation>
        <LoadingAnimationDot />
        <LoadingAnimationDot />
        <LoadingAnimationDot />
      </LoadingAnimation>
      <LoadingText>
        <Typography
          sx={{
            fontSize: "21px",
            color: colors.zymegoGreen,
          }}
        >
          {text}
        </Typography>
      </LoadingText>
    </LoadingWrapper>
  );
}

export { Loading };
