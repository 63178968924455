import { init as initApm } from "@elastic/apm-rum";

import { getConfig } from "./runtime-config";

const { ELASTIC_APM_AGENT_ACTIVE, ELASTIC_APM_SERVER_URL } = getConfig();

if (ELASTIC_APM_SERVER_URL) {
  initApm({
    serviceName: "patient-react",
    serverUrl: ELASTIC_APM_SERVER_URL,
    serviceVersion: "1.0.0",
    environment: import.meta.env.MODE,
    active: ELASTIC_APM_AGENT_ACTIVE,
  });
}
