query GetAppointment($id: UUID!) {
  appointment: booking(id: $id) {
    id
    onWaitlist
    onWishlist
    startAt: visibleStartAt
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    canCancel
    careUnit {
      enabledFeatures {
        id
        name
      }
      id
      name
    }
    bookingType {
      id
      wishlistEnabled
    }
    caregiver {
      name
      id
    }
    wishlistWindow {
      requiredDays
      minDate
      maxDate
    }
  }
}
