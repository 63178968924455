query GetNewAppointmentStatus($requestId: UUID!) {
  createAppointmentRequest(id: $requestId) {
    timeslot {
      bookingType {
        id
      }
      id
      caregiverId
      startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
      endAtInCareUnitsTimezone: visisbleEndAtInCareUnitsTimezone
      caregiver {
        id
        name
      }
      careUnit {
        id
        address
        postAddress
        postCode
        name
      }
    }

    id
    careUnitId
    careUnit {
      externalHomePageUrl
      id
      name
    }
    status
    updatedAt
    createdAt
  }

  # patientAppointments is a hack, see comment on app_public.patient_appointments
  appointments: patientAppointments {
    id
    startAtInCareUnitsTimezone
    caregiver {
      id
      name
    }
    careUnit {
      id
      address
      postAddress
      postCode
      name
    }
  }
}
