import { useLingui } from "@lingui/react";
import { format } from "date-fns";
import { useMemo } from "react";

type DateFormatter = {
  formatDate: (date: Date) => string;
  formatDateTime: (date: Date) => string;
  formatTime: (date: Date) => string;
};

const dateFormats: Record<string, string | undefined> = {
  ar: "E dd MMM",
  cy: "E dd MMM",
  en: "E dd MMM",
  sv: "E dd MMM",
};

const timeFormats: Record<string, string | undefined> = {
  ar: "k:mm",
  cy: "k:mm",
  en: "h:mm aa",
  sv: "k:mm",
};

export const useDateFormatter = (): DateFormatter => {
  const { i18n } = useLingui();

  return useMemo(() => {
    const dateFormat = dateFormats[i18n.locale] ?? "E dd MMM";
    const timeFormat = timeFormats[i18n.locale] ?? "k:mm";

    return {
      formatDate: (date) => {
        return format(date, dateFormat);
      },
      formatDateTime: (date) => {
        return format(date, `${dateFormat} ${timeFormat}`);
      },
      formatTime: (date) => {
        return format(date, timeFormat);
      },
    };
  }, [i18n.locale]);
};
