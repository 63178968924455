import { t } from "@lingui/macro";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { isFeatureEnabled } from "../feature-flags";
import { useUrls } from "../urls";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";

function PrivateRouteConsent() {
  const location = useLocation();
  const navigate = useNavigate();
  const urls = useUrls();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  useEffect(() => {
    if (!loading && patient && !patient.consent) {
      navigate({ pathname: "consent", search: location.search });
      return;
    }
  }, [navigate, loading, location.search, patient]);

  useEffect(() => {
    if (
      !isFeatureEnabled("consentForConfirmationsAndReminders") ||
      loading ||
      patient?.consentForConfirmationsAndReminders != null ||
      location.pathname.startsWith(urls.onboarding.index)
    ) {
      return;
    }

    const isReturningPatient = Boolean(patient?.email || patient?.phoneNumber);
    if (isReturningPatient) {
      navigate({ pathname: urls.consent.welcomeBack });
    } else {
      navigate({ pathname: urls.onboarding.index });
    }
  }, [
    loading,
    location.pathname,
    navigate,
    patient?.consentForConfirmationsAndReminders,
    patient?.email,
    patient?.phoneNumber,
    urls.consent.welcomeBack,
    urls.onboarding.index,
  ]);

  if (loading) {
    return null;
  }

  if (error || !patient) {
    throw new Error(t`Something went wrong`);
  }

  if (!patient.consent) {
    // will be redirected from useEffect
    return null;
  }

  return <Outlet />;
}

export { PrivateRouteConsent };
