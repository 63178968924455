import { Trans } from "@lingui/macro";
import { Box, Button, Link as MuiLink, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ThumbsUp } from "../icons/thumbs-up.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors } from "../theme";

function LeaveWaitlistStatusDialog() {
  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  function handleDialogClose() {
    setIsDialogOpen(false);
  }
  return (
    <DialogBottom
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          padding: 3,
          textAlign: "center",
        }}
      >
        <ThumbsUp height={150} />

        <Typography align="center" padding={2} variant="h4">
          <Trans>You will no longer get offers</Trans>
        </Typography>
        <Typography
          align="center"
          color={colors.zymegoDarkGreen}
          padding={2}
          sx={{ opacity: "60%" }}
          variant="subtitle2"
        >
          <Trans>
            If you change your mind you can activate offers through "Manage
            booking".
          </Trans>
        </Typography>

        <Button fullWidth onClick={handleDialogClose} variant="contained">
          <Trans>Close</Trans>
        </Button>
      </Box>
    </DialogBottom>
  );
}

export { LeaveWaitlistStatusDialog };
