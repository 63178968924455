import { Trans } from "@lingui/macro";
import {
  Alert,
  Box,
  Button as MuiButton,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import { Button } from "../../components/button/button";
import { ReactComponent as TurtleIcon } from "../../icons/turtle.svg";
import { colors } from "../../theme";

function RevokedOffer() {
  return (
    <Grid
      alignItems="stretch"
      container
      direction="column"
      flexGrow={1}
      sx={{
        padding: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Grid item>
        <Card
          sx={{
            border: "2px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: colors.white,
            boxShadow: "0px 16px 16px -11px rgba(0, 0, 0, 0.05)",
            borderRadius: "13px",
            overflow: "visible",
            textAlign: "center",
            color: colors.zymegoGreen,
          }}
        >
          <Box
            sx={{
              width: "calc(100% + 24px)",
              marginLeft: "-12px",
              backgroundColor: colors.white,
              border: "2px solid rgba(137, 137, 137, 0.75)",
              borderRadius: "12px",
              padding: "11px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F0F0F0",
                borderRadius: "5px",
                padding: "16px",
                color: "#000000",
              }}
            >
              <TurtleIcon
                style={{ fill: "currentcolor", height: "37px", width: "46px" }}
              />
              <Typography
                sx={{
                  fontSize: "15px",

                  textTransform: "uppercase",
                  fontWeight: 700,
                  color: colors.zymegoStone,
                }}
              >
                <Trans>Unfortunately someone else accepted first</Trans>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: { sm: 3, xs: 2 },
            }}
          >
            <Typography fontWeight={600} variant="info">
              <Trans>
                The offered time slot is no longer available, someone else
                accepted first.
              </Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              padding: {
                xs: 2,
                sm: 3,
              },
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <Alert severity="info">
              <Trans>You still have your original appointment.</Trans>
            </Alert>
          </Box>
        </Card>
      </Grid>
      <Grid item marginTop={1} paddingBottom={3}>
        <MuiButton
          color="secondary"
          component={Link}
          fullWidth
          to="/"
          variant="contained"
        >
          <Trans>My Appointments</Trans>
        </MuiButton>
        <Button design="transparent" href="/logout" icon="logout">
          <Trans>Sign out</Trans>
        </Button>
      </Grid>
    </Grid>
  );
}

export { RevokedOffer };
