import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

function Direction({
  children,
  direction,
}: PropsWithChildren<{ direction: "ltr" | "rtl" }>) {
  return (
    <>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
        <html dir={direction}></html>
      </Helmet>
      {direction === "rtl" ? (
        <CacheProvider value={cacheRtl}>{children}</CacheProvider>
      ) : (
        children
      )}
    </>
  );
}

export { Direction };
