query GetCurrentUserAndAppointment($appointmentId: UUID!) {
  appointment: booking(id: $appointmentId) {
    id
    careUnit {
      id
      name
    }
    onWaitlist
  }

  patient: currentPatient {
    id
    email
    phoneNumber
    notificationType
  }
}
