import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { Layout } from "../layout";
import { getConfig } from "../runtime-config";
import { useUrls } from "../urls";
import { NhsLoginButton } from "./nhs-login-button";
import { startNhsSignIn } from "./sign-in";
import { useAuthLevel } from "./user-auth-level";

const { AUTH_IDENTITY_PROVIDER } = getConfig();

export function NhsSignInPage() {
  const { satisfiesAuthLevel } = useAuthLevel();
  const [searchParameters] = useSearchParams();
  const urls = useUrls();

  if (AUTH_IDENTITY_PROVIDER !== "nhs" || satisfiesAuthLevel("medium")) {
    window.location.pathname = urls.bookings.new.index;
    return null;
  }

  const clinicName = searchParameters.get("clinicName");

  return (
    <Layout headerColor="light">
      <Box sx={{ padding: 3 }}>
        <Typography
          align="center"
          color="secondary"
          fontSize={21}
          fontWeight={700}
          marginBottom={2}
        >
          <Trans>Welcome!</Trans>
        </Typography>
        <Typography
          align="center"
          fontSize={17}
          fontWeight={500}
          sx={{ marginBottom: 2 }}
        >
          <Trans>
            To book a new appointment, you need to sign in via NHS login. A
            medium-level identity verification is required.
          </Trans>
        </Typography>
        <NhsLoginButton
          onClick={() => {
            startNhsSignIn({
              authLevel: "medium",
              state: {
                locationState: {
                  pathname: urls.bookings.new.index,
                  search: clinicName ? `?clinicName=${clinicName}` : undefined,
                  state: globalThis.history.state,
                },
              },
            });
          }}
        />
      </Box>
    </Layout>
  );
}
