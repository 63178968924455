import { t } from "@lingui/macro";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { client } from "../apollo-client";
import { Layout } from "../layout";
import { Loading } from "../loading";
import { useGetSwitchSessionRequestQuery } from "./get-switch-session.graphql";

function SwitchSessionStatus() {
  const { id } = useParams();
  const navigate = useNavigate();

  invariant(id);

  const { data, error, loading } = useGetSwitchSessionRequestQuery({
    variables: {
      id,
    },
    pollInterval: 1000,
  });

  const requestInProgress =
    loading ||
    data?.switchSessionRequest?.status === "QUEUED" ||
    data?.switchSessionRequest?.status === "IN_PROGRESS";

  const requestErrored =
    error ||
    data?.switchSessionRequest?.status === "FAILED" ||
    data?.switchSessionRequest?.status === "UNSUCCESSFUL";

  if (requestInProgress) {
    return (
      <Layout>
        <Loading logo={false} text={t`Validating guardianship...`} />
      </Layout>
    );
  }

  if (requestErrored) {
    throw new Error(t`We could not validate the guardianship.`);
  }

  navigate("/");

  client.resetStore();
  return null;
}

export { SwitchSessionStatus };
