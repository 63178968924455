import { useEffect } from "react";

import { getGeographyConfig } from "../geographies";
import { userManager } from "../user-manager";

const { signoutTimers } = getGeographyConfig();

export function useSessionExpirySignout(): void {
  useEffect(() => {
    const sessionExpirySeconds = signoutTimers?.sessionExpirySeconds;

    if (!sessionExpirySeconds || !Number.isFinite(sessionExpirySeconds)) {
      return;
    }

    const timeout = window.setTimeout(() => {
      userManager.signoutRedirect();
    }, sessionExpirySeconds * 1000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, []);
}
