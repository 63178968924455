import { Plural, Trans } from "@lingui/macro";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { colors } from "../../theme";
import { useGetOffersQuery } from "./get-offers.graphql";

function OfferNotification() {
  const {
    data: queryData,
    error: queryError,
    loading: queryLoading,
  } = useGetOffersQuery({
    pollInterval: 1000,
  });

  const activeOfferCount =
    queryData?.offers?.filter((offer) => {
      return offer.status === "UNDECIDED";
    })?.length ?? 0;

  if (queryError || queryLoading) {
    return null;
  }

  return (
    <Box sx={{ paddingX: { sm: 3, xs: 2 }, paddingTop: { sm: 3, xs: 2 } }}>
      <Card
        sx={{
          border: "2px solid rgba(0, 0, 0, 0.2)",
          borderRadius: "16pxs",
          textAlign: "center",
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{ padding: { sm: 3, xs: 2 } }}
        >
          <Typography sx={{ fontWeight: 500 }} typography="h3">
            <Plural
              one={
                <Trans>
                  You have <strong>1</strong> offer!
                </Trans>
              }
              other={
                <Trans>
                  You have <strong>#</strong> offers!
                </Trans>
              }
              value={activeOfferCount}
            />
            &ensp;
            <Box sx={{ display: "inline" }}>🎉</Box>
          </Typography>
          <Typography
            color={colors.zymegoGreen}
            fontSize="15px"
            fontWeight={600}
          >
            <Trans>Click the button to view and accept offers!</Trans>
          </Typography>
        </Stack>

        <Box
          sx={{
            padding: { sm: 3, xs: 2 },

            backgroundColor: colors.zymegoLightGreen,
          }}
        >
          <Button
            component={Link}
            endIcon={<ArrowRight style={{ fill: "currentcolor" }} />}
            fullWidth
            sx={{ margin: 0 }}
            to="offers"
            variant="contained"
          >
            <Trans>View offers</Trans>
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
export { OfferNotification };
