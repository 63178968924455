query GetAppointmentAndTimeslot($appointmentId: UUID!, $timeslotId: UUID!) {
  appointment: booking(id: $appointmentId) {
    id
    startAt: visibleStartAt
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
  }
  timeslot: booking(id: $timeslotId) {
    id
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    caregiver {
      name
    }
  }
}
