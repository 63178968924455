import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { LoadingButton } from "@mui/lab";
import { Avatar, Button, Link as MuiLink, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Radio } from "../components/radio/radio";
import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { isWishlistEnabled } from "../utils";
import { useLeaveWishlistMutation } from "../wishlist/leave-wishlist.graphql";
import { useCancellationRequestMutation } from "./cancellation-request.graphql";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

interface FormValues {
  leaveWishlistRequestType: string;
}
//manage remove from wishlist/waitlist while canceling appointment
function Request() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId = "" } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const navigate = useNavigate();

  const [appointmentIsOnWishlist, setAppointmentIsOnWishlist] = useState("");
  const { control, handleSubmit } = useForm<FormValues>();

  const [
    cancellationRequest,
    { loading: cancellationLoading, error: cancellationError },
  ] = useCancellationRequestMutation();

  const {
    data: { appointment } = {},
    loading,
    error,
  } = useGetAppointmentQuery({
    variables: { id: appointmentId },
  });

  const [
    leaveWishlist,
    { loading: leaveWishlistLoading, error: leaveWishlistError },
  ] = useLeaveWishlistMutation();

  const onSubmit = async (data: FormValues) => {
    if (data.leaveWishlistRequestType === "false") {
      await leaveWishlist({ variables: { appointmentId } });
      const { data: cancellationData } = await cancellationRequest({
        variables: {
          appointmentId,
        },
      });

      navigate(
        `../status/${cancellationData?.cancelAppointment?.cancelAppointmentRequest?.id}`,
        { state: { backgroundLocation } },
      );
      return;
    }
    navigate("../contact-info", { state: { backgroundLocation } });
  };

  if (appointment && appointment.careUnit && appointment.bookingType) {
    const { careUnit, bookingType } = appointment;
    const wishlistEnabled = isWishlistEnabled({ careUnit, bookingType });
    invariant(!wishlistEnabled); // This page is only reachable if wishlist is not enabled
  }

  if (loading) {
    return null;
  }
  if (leaveWishlistError || cancellationError) {
    throw new Error(t`Something went wrong while processing your request.`);
  }
  if (error || (!loading && !appointment)) {
    throw new Error(t`We couldn't find your appointment.`);
  }
  return (
    <DialogBottom
      onClose={() => setIsDialogOpen(false)}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          color={colors.blue}
          onClick={() => navigate(-1)}
          underline="none"
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      {appointment?.onWishlist ? (
        <Box
          sx={{
            textAlign: "center",
            paddingX: { xs: 2, sm: 3 },
            paddingBottom: { xs: 2, sm: 3 },
          }}
        >
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                padding: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.zymegoLightTravertine,
                  borderRadius: "8px",
                  display: "inline-flex",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                  gap: "8px",
                }}
              >
                <Avatar
                  sx={{
                    width: "27px",
                    height: "27px",
                    color: colors.zymegoDarkGreen,
                    backgroundColor: colors.zymegoTravertine,
                  }}
                >
                  <PriorityHighIcon sx={{ fontSize: 20 }} />
                </Avatar>

                <Typography variant="h4">
                  <Trans>
                    You have previously requested days for an appointment.
                  </Trans>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: 1,
              }}
            >
              <Typography align="center" variant="h4">
                <Trans>Do you still want another appointment? </Trans>
              </Typography>
            </Box>
            <Box
              sx={{
                border: "2px solid #E6E6E6",
                borderRadius: "6px",
                padding: "0.7rem",
              }}
            >
              <Controller
                control={control}
                defaultValue=""
                name="leaveWishlistRequestType"
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setAppointmentIsOnWishlist(event.target.value);
                    }}
                  >
                    <Radio label={t`YES`} value="true" />
                    <Radio label={t`NO`} value="false" />
                  </Radio.Group>
                )}
                rules={{
                  required: t`required.`,
                }}
              />
            </Box>

            {appointmentIsOnWishlist === "" ? (
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  opacity: "60%",
                  height: "100px",
                }}
              >
                <Typography
                  align="center"
                  color={colors.zymegoDarkGreen}
                  variant="h6"
                >
                  <Trans>
                    Choose if you want to stay in the waiting list to get a new
                    time.
                  </Trans>
                </Typography>
              </Box>
            ) : appointmentIsOnWishlist === "false" ? (
              <Box
                sx={{
                  height: "100px",
                  padding: 2,
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: "7px",
                    gap: "8px",
                    backgroundColor: colors.zymegoPowder,
                    padding: {
                      xs: 1,
                      sm: 2,
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: colors.zymegoRose,
                      color: colors.zymegoDarkGreen,
                      fontSize: "large",
                    }}
                  >
                    <PriorityHighIcon sx={{ fontSize: 20 }} />
                  </Avatar>

                  <Typography align="left" variant="h6">
                    <Trans>You won't get a new appointment!</Trans>
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  opacity: "60%",
                  height: "100px",
                }}
              >
                <Typography
                  align="center"
                  color={colors.zymegoDarkGreen}
                  variant="h6"
                >
                  <Trans>
                    If a time that suits you becomes available, we will send a
                    booking confirmation.
                  </Trans>
                </Typography>
              </Box>
            )}

            <Box>
              <LoadingButton
                disabled={
                  appointmentIsOnWishlist === "" ||
                  leaveWishlistLoading ||
                  cancellationLoading
                }
                endIcon={
                  theme.direction === "ltr" ? (
                    <ArrowRight style={{ fill: "currentColor" }} />
                  ) : (
                    <ArrowLeft style={{ fill: "currentcolor" }} />
                  )
                }
                fullWidth
                loadingPosition="end"
                size="large"
                type="submit"
                variant="contained"
              >
                <Trans>Next</Trans>
              </LoadingButton>
            </Box>
          </form>
        </Box>
      ) : (
        <Box>
          <Box>
            <Box
              sx={{
                justifyContent: "center",
                textAlign: "center",
                borderBottom: "1px solid rgba(0 ,0 ,0 ,0.1)",
                padding: { sm: 2, xs: 1 },
                paddingTop: "0",
              }}
            >
              <Typography variant="h5">
                <Trans>Note!</Trans>
              </Typography>
              <Typography variant="h5">
                <Trans>Important Information</Trans>
              </Typography>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid rgba(0 ,0 ,0 ,0.1)",
                padding: { sm: 2, xs: 1 },
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.zymegoLightTravertine,
                  borderRadius: "8px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                  gap: "8px",
                }}
              >
                <Avatar
                  sx={{
                    width: "27px",
                    height: "27px",
                    color: colors.zymegoDarkGreen,
                    backgroundColor: colors.zymegoTravertine,
                    fontWeight: "bold",
                  }}
                >
                  i
                </Avatar>
                <Typography variant="h6">
                  <Trans>
                    If you want a new appointment, go back and select "I want to
                    reschedule".
                    <br />
                    <br /> If you don’t find a suitable time slot in the
                    rescheduling calendar, proceed to cancel.
                  </Trans>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: { sm: 2, xs: 1 }, paddingBottom: 1 }}>
            <Button
              color="secondary"
              component={Link}
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              fullWidth
              replace
              size="large"
              state={{
                backgroundLocation,
              }}
              to={
                appointment?.bookingType?.cancellationReasonRequired
                  ? "../reason"
                  : "../confirm"
              }
              variant="contained"
            >
              <Trans>Proceed to cancel</Trans>
            </Button>
          </Box>
        </Box>
      )}
    </DialogBottom>
  );
}
export { Request };
