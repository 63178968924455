import { t } from "@lingui/macro";
import { Grid } from "@mui/material";
import { Control, Controller } from "react-hook-form";

import { Radio } from "../components/radio/radio";

function ListOfRadioButtons({
  properties,
  formProperties,
  selectAll,
}: {
  groupName?: string;
  properties?: Array<{ id: string; name: string | null }>;
  disabledProperties?: Array<{ id: string; name: string | null }>;
  formProperties: {
    control: Control<{ id: string }>;
  };
  selectAll?: boolean;
}) {
  return (
    <Grid
      border="1px solid rgba(0,0,0,.12)"
      borderRadius="17px"
      container
      direction="column"
      marginBottom={10}
    >
      <Controller
        control={formProperties.control}
        defaultValue={""}
        name="id"
        render={({ field }) => {
          return (
            <Radio.Group {...field} defaultValue="">
              {selectAll && (
                <Grid
                  item
                  sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    paddingX: 4,
                    paddingY: 1,
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  <Radio label={t`All caregivers`} value="" />
                </Grid>
              )}
              {properties?.map((property) => (
                <Grid
                  item
                  key={property.id}
                  sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    paddingX: 4,
                    paddingY: 1,
                    textAlign: "start",
                    width: "100%",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <Radio label={property.name} value={property.id} />
                </Grid>
              ))}
            </Radio.Group>
          );
        }}
      />
    </Grid>
  );
}

export { ListOfRadioButtons };
