query GetCurrentPatient {
  patient: currentPatient {
    consentForConfirmationsAndReminders
    id
    identifier
    email
    phoneNumber
  }
  guardian: currentGuardianPatient {
    id
    identifier
  }
}
