mutation UpdatePatientConsent(
  $id: UUID!
  $consentForConfirmationsAndReminders: Boolean!
) {
  updatePatient(
    input: {
      id: $id
      patch: {
        consentForConfirmationsAndReminders: $consentForConfirmationsAndReminders
      }
    }
  ) {
    patient {
      id
      consentForConfirmationsAndReminders
    }
  }
}
