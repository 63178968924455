import { PriorityHigh } from "@mui/icons-material";
import {
  Alert as MuiAlert,
  AlertColor,
  Avatar,
  Box,
  SxProps,
} from "@mui/material";
import { ReactNode } from "react";

import { colors } from "../../theme";

type AlertProperties = {
  children: ReactNode;
  severity: AlertColor;
  sx?: SxProps;
};

export function Alert({ children, severity, sx }: AlertProperties) {
  if (severity === "error") {
    return (
      <Box
        sx={{
          backgroundColor: colors.zymegoPowderLight,
          borderRadius: "7px",
          display: "inline-flex",
          gap: "12px",
          justifyContent: "center",
          padding: { xs: 1, sm: 2 },
          ...sx,
        }}
      >
        <Avatar
          sx={{
            backgroundColor: colors.zymegoPowder,
            color: "inherit",
            fontSize: "large",
            height: "30px",
            width: "30px",
          }}
        >
          <PriorityHigh sx={{ fontSize: 20 }} />
        </Avatar>
        {children}
      </Box>
    );
  }

  return (
    <MuiAlert severity={severity} sx={{ margin: 0, textAlign: "left", ...sx }}>
      {children}
    </MuiAlert>
  );
}
