import { Trans } from "@lingui/macro";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Typography } from "@mui/material";

import { colors } from "../theme";

interface differentColorForAlternativeCaregiversProperties {
  caregiverFilter: string;
  setCaregiverFilter: React.Dispatch<
    React.SetStateAction<"all" | "same" | "other">
  >;
}

function DifferentColorForAlternativeCaregivers({
  caregiverFilter,
  setCaregiverFilter,
}: differentColorForAlternativeCaregiversProperties) {
  const styles = {
    caregiverFilterButton: {
      padding: 0,
      margin: "0 8px 0 0",
      width: "28px",
      minWidth: "28px",
      height: "24px",
      fontWeight: "700",
      borderRadius: "8px",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: {
        xs: "8px 16px",
        sm: "8px 16px",
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          padding: "16px 40px 0 40px",
          color: colors.zymegoDarkGreen,
          textAlign: "center",
        }}
      >
        <Typography
          component="h6"
          sx={{ color: colors.zymegoGreen }}
          variant="boldInfo"
        >
          <Trans>Select your preferred time</Trans>
        </Typography>
        <Typography
          component="p"
          sx={{ color: colors.zymegoGreen }}
          variant="boldBody2"
        >
          <Trans>
            Click on the pink box to only show time slots for the caregiver on
            your current booking
          </Trans>
        </Typography>
      </Box>
      <Box sx={styles.buttonsContainer}>
        <Box sx={styles.flexCenter}>
          <Button
            onClick={() =>
              setCaregiverFilter((previousFilter) =>
                previousFilter === "same" ? "all" : "same",
              )
            }
            sx={{
              ...styles.caregiverFilterButton,
              background: `${colors.zymegoPowder} !important`,
              "& .MuiTouchRipple-root span": {
                backgroundColor: colors.zymegoPowder,
              },
            }}
          >
            {caregiverFilter === "same" && (
              <CheckIcon htmlColor={colors.zymegoGreen} />
            )}
          </Button>
          <Typography sx={{ color: colors.zymegoGreen }} variant="boldBody2">
            <Trans>Current caregiver</Trans>
          </Typography>
        </Box>
        <Box sx={styles.flexCenter}>
          <Button
            onClick={() => {
              setCaregiverFilter((previousFilter) =>
                previousFilter === "other" ? "all" : "other",
              );
            }}
            sx={{
              ...styles.caregiverFilterButton,
              background: `${colors.zymegoSpearMint} !important`,
              "& .MuiTouchRipple-root span": {
                backgroundColor: colors.zymegoSpearMint,
              },
            }}
          >
            {caregiverFilter === "other" && (
              <CheckIcon htmlColor={colors.zymegoGreen} />
            )}
          </Button>
          <Typography sx={{ color: colors.zymegoGreen }} variant="boldBody2">
            <Trans>Other Caregiver</Trans>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export { DifferentColorForAlternativeCaregivers };
