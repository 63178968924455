query GetReferrals {
  referrals {
    id
    bookingType {
      visibleName
      name
    }
    careUnit {
      name
      address
    }
    caregiver {
      name
    }
  }
}
