mutation UpdatePatient(
  $email: String!
  $notificationType: PatientNotificationTypes!
  $patientId: UUID!
  $phoneNumber: String
) {
  updatePatient(
    input: {
      id: $patientId
      patch: {
        notificationType: $notificationType
        email: $email
        phoneNumber: $phoneNumber
      }
    }
  ) {
    patient {
      id
      notificationType
      email
      phoneNumber
    }
  }
}
