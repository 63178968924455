import { Trans } from "@lingui/macro";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Avatar, Button, Link as MuiLink, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { DialogBottom } from "../dialog-bottom";
import { colors, theme } from "../theme";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function InfoMaxReschedules() {
  const [isOpen, setIsOpen] = useState(true);
  const { appointmentId: id = "" } = useParams();

  const { data: { appointment } = {} } = useGetAppointmentQuery({
    variables: {
      id,
    },
  });

  const navigate = useNavigate();

  return (
    <DialogBottom
      onClose={() => setIsOpen(false)}
      onExited={() => navigate("..", { replace: true })}
      open={isOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          paddingX: { sx: 2, sm: 3 },
        }}
      >
        <Box
          sx={{
            paddingX: { sx: 2, sm: 3 },
          }}
        >
          <Typography
            fontSize="22px"
            fontWeight="700"
            pb={2}
            textAlign="center"
          >
            <Trans>Important Information</Trans>
          </Typography>
        </Box>
        <Box
          sx={{
            paddingX: { sx: 1, sm: 2 },
            marginBottom: { sx: 1, sm: 2 },
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: "7px",
              background: "rgba(248,244,231,0.2)",
              backgroundColor: colors.zymegoLightTravertine,
              padding: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                marginRight: theme.direction === "ltr" ? "8px" : "0px",
                marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                backgroundColor: colors.zymegoTravertine,
                color: colors.zymegoDarkGreen,
                fontSize: "large",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "50%",
              }}
            >
              <PriorityHighIcon sx={{ fontSize: 20 }} />
            </Avatar>

            <Typography
              align="left"
              color={colors.zymegoDarkGreen}
              fontSize="17px"
              fontWeight="600"
              sx={{
                background: "rgba(0, 0, 0, 0)",
              }}
            >
              <Trans>
                You can reschedule a{" "}
                <span style={{ fontSize: "17px", fontWeight: "700" }}>
                  maximum
                </span>{" "}
                of{" "}
                <span style={{ fontSize: "17px", fontWeight: "700" }}>
                  {appointment?.bookingType?.maxRescheduleCount}
                </span>{" "}
                times, according to the clinic's rules.
              </Trans>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: { sx: 2, sm: 3 },

          marginBottom: 3,
        }}
      >
        <Button
          component={Link}
          fullWidth
          replace
          sx={{ marginBottom: 2 }}
          to=".."
          variant="contained"
        >
          <Trans>I got it!</Trans>
        </Button>
      </Box>
    </DialogBottom>
  );
}
export { InfoMaxReschedules };
