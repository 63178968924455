query GetTimeslot($timeslotId: UUID!) {
  timeslot: booking(id: $timeslotId) {
    id
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    endAtInCareUnitsTimezone: visisbleEndAtInCareUnitsTimezone
    careUnit {
      name
      address
      postAddress
      postCode
    }

    bookingType {
      name
      visibleName
    }

    caregiver {
      name
      id
    }
  }
}
