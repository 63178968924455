import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Link as MuiLink, TextField, Typography } from "@mui/material";
import { PatientNotificationTypes } from "graphql-let/__generated__/__types__";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { ConsentForConfirmationsAndRemindersField } from "../consent/consent-for-confirmations-and-reminders-field";
import { DialogBottom } from "../dialog-bottom";
import { isFeatureEnabled } from "../feature-flags";
import { getGeographyConfig } from "../geographies";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { NhsContactInfoUpdateText } from "../nhs/contact-info-update-text";
import { NotificationTypeField } from "../notifications/notification-type-field";
import { useBackgroundLocation } from "../router/background-location-context";
import { getConfig } from "../runtime-config";
import { colors, theme } from "../theme";
import { useGetTimeslotAndCurrentPatientQuery } from "./get-timeslot-and-current-patient.graphql";
import { useRescheduleAppointmentAndUpdatePatientMutation } from "./reschedule-appointment-and-update-patient.graphql";

interface FormValues {
  consentForConfirmationsAndReminders: "true" | "false";
  email: string;
  notificationType: PatientNotificationTypes;
  phoneNumber?: string;
}

const { AUTH_IDENTITY_PROVIDER } = getConfig();

function ManagePatientInformationInReschedule() {
  const backgroundLocation = useBackgroundLocation();
  const { timeslotId = "", appointmentId = "" } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);

  const { countryCode } = getGeographyConfig();
  const navigate = useNavigate();

  const {
    data: { timeslot, patient } = {},
    loading,
    error,
  } = useGetTimeslotAndCurrentPatientQuery({
    variables: { timeslotId },
  });

  const [
    rescheduleAppointmentAndUpdatePatient,
    { loading: mutationLoading, error: mutationError },
  ] = useRescheduleAppointmentAndUpdatePatientMutation();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    watch,
  } = useForm<FormValues>();

  if (mutationError) {
    throw new Error(
      t`We couldn't reschedule your appointment. Please try again or contact the clinic`,
    );
  }

  if (loading && (!timeslot || !patient)) {
    return null;
  }

  if (error || mutationError || !patient) {
    throw new Error(t`Something went wrong`);
  }

  const onSubmit: SubmitHandler<FormValues> = async ({
    consentForConfirmationsAndReminders,
    phoneNumber: formattedPhoneNumber = "",
    email,
  }) => {
    // If empty string is passed, it will be converted to undefined

    const phoneNumber =
      parsePhoneNumberFromString(formattedPhoneNumber)?.number ?? null;

    const notificationType = phoneNumber ? "SMS" : "EMAIL";

    try {
      const { data: mutationData } =
        await rescheduleAppointmentAndUpdatePatient({
          variables: {
            appointmentId,
            timeslotId,
            patientId: patient.id,
            patient: {
              email,
              phoneNumber,
              notificationType,
              consentForConfirmationsAndReminders:
                consentForConfirmationsAndReminders == null
                  ? undefined
                  : consentForConfirmationsAndReminders === "true",
            },
          },
        });

      navigate(
        `../status/${mutationData?.rescheduleAppointment?.rescheduleAppointmentRequest?.id}`,
        { replace: true },
      );
    } catch {
      // Ignore
    }
  };

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  const formValues = watch();

  const isFormValid = () => {
    if (!isFeatureEnabled("consentForConfirmationsAndReminders")) {
      return true;
    }

    const { email, phoneNumber, consentForConfirmationsAndReminders } =
      formValues;
    const isEmailValid = email && /^.+@.+$/.test(email);
    const isPhoneNumberValid = !phoneNumber || matchIsValidTel(phoneNumber);
    const isConsentGiven =
      consentForConfirmationsAndReminders === "true" ||
      consentForConfirmationsAndReminders === "false";

    return isEmailValid && isPhoneNumberValid && isConsentGiven;
  };

  if (!patient && loading) {
    return null;
  }

  return (
    <DialogBottom
      onClose={handleDialogClose}
      onExited={() => navigate(backgroundLocation.pathname, { replace: true })}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          color={colors.blue}
          onClick={() => navigate(-1)}
          underline="none"
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box sx={{ textAlign: "center", paddingX: { xs: 2, sm: 3 } }}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ paddingX: { xs: 1, sm: 2 } }}>
            <Typography align="center" variant="h4">
              <Trans>Confirmation will be sent to</Trans>
            </Typography>
            {AUTH_IDENTITY_PROVIDER === "nhs" && (
              <NhsContactInfoUpdateText sx={{ marginY: 1 }} />
            )}
            <Box sx={{ marginBottom: "17px" }}>
              <Controller
                control={control}
                defaultValue={patient.phoneNumber ?? ""}
                name="phoneNumber"
                render={({ field, fieldState }) => {
                  return (
                    <MuiTelInput
                      data-cy="reschedule-contact-phone"
                      sx={{ direction: "ltr" }}
                      {...field}
                      defaultCountry={countryCode}
                      error={Boolean(fieldState.error)}
                      forceCallingCode
                      fullWidth
                      helperText={errors.phoneNumber?.message}
                      label={t`Phone Number`}
                    />
                  );
                }}
                rules={{
                  validate(value?: string) {
                    const notificationType = getValues("notificationType");

                    if (notificationType === "SMS" && !value) {
                      return t`Phone number is required.`;
                    }
                    if (
                      notificationType === "SMS" &&
                      value &&
                      !matchIsValidTel(value)
                    )
                      return t`Please provide the phone number in a valid format, example: +46701234567`;
                  },
                }}
              />
              <Controller
                control={control}
                defaultValue={patient?.email ?? ""}
                name="email"
                render={({ field }) => (
                  <TextField
                    data-cy="reschedule-contact-email"
                    sx={{ direction: "ltr" }}
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors.email)}
                    fullWidth={true}
                    helperText={errors.email?.message}
                    label={t`Email`}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    size="medium"
                    type="email"
                  />
                )}
                rules={{
                  required: t`Email is required.`,
                  pattern: {
                    value: /^.+@.+$/,
                    message: t`Please provide a valid email address.`,
                  },
                }}
              />
            </Box>
            {isFeatureEnabled("consentForConfirmationsAndReminders") ? (
              <ConsentForConfirmationsAndRemindersField
                control={control as unknown as Control}
                showDescription
              />
            ) : (
              <NotificationTypeField
                control={control as unknown as Control}
                defaultValue={patient.notificationType}
                errorMessage={errors.notificationType?.message}
              />
            )}
            <Box sx={{ position: "relative", marginTop: "20px" }}>
              <LoadingButton
                data-cy="reschedule-contact-confirm-button"
                disabled={!isFormValid() || isSubmitting || mutationLoading}
                endIcon={
                  theme.direction === "ltr" ? (
                    <ArrowRight style={{ fill: "currentColor" }} />
                  ) : (
                    <ArrowLeft style={{ fill: "currentcolor" }} />
                  )
                }
                fullWidth
                loading={isSubmitting || mutationLoading}
                loadingPosition="end"
                size="large"
                type="submit"
                variant="contained"
              >
                <Trans>Confirm</Trans>
              </LoadingButton>

              <Typography
                align="center"
                color={colors.zymegoDarkGreen}
                sx={{ marginBottom: 2, opacity: "60%" }}
                variant="subtitle2"
              >
                <Trans>
                  When you reschedule, your current appointment will be
                  replaced.
                </Trans>
              </Typography>
            </Box>
          </Box>
        </form>
      </Box>
    </DialogBottom>
  );
}

export { ManagePatientInformationInReschedule };
