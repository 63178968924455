mutation SubmitFeedback(
  $feedback: String!
  $careUnitId: UUID
  $rating: Int
  $canContactUser: Boolean
) {
  submitFeedback(
    input: {
      feedback: $feedback
      careUnitId: $careUnitId
      rating: $rating
      canContactUser: $canContactUser
    }
  ) {
    success
  }
}
