query GetTimeslotAndCurrentPatient($timeslotId: UUID!) {
  timeslot: booking(id: $timeslotId) {
    id
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
  }
  patient: currentPatient {
    id
    email
    notificationType
    phoneNumber
  }
}
