import { Trans } from "@lingui/macro";
import { Box, MenuItem, Select, styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { formatPatientIdentifier } from "../patient/identifier";
import { colors } from "../theme";
import { useGetProfileSelectorDataQuery } from "./get-profile-selector-data.graphql";
import { useSwitchSessionMutation } from "./switch-session.graphql";

const SwitchProfileButton = styled(Select)`
  color: ${colors.zymegoDarkGreen};
  cursor: pointer;
  position: flex;
  background: ${colors.white};
  align-items: center;
  justify-content: center;
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Gellix";
  .MuiOutlinedInput-notchedOutline {
    border-width: 2px solid !important;
    border-radius: 12px !important;
    border-color: ${colors.zymegoSand}!important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.zymegoSand}!important;
  }
`;

function ProfileSelector() {
  const {
    data: { guardian, guardianships, patient } = {},
    loading,
    error,
  } = useGetProfileSelectorDataQuery();

  const [
    switchSession,
    {
      data: switchSessionData,
      error: switchSessionError,
      loading: switchSessionLoading,
    },
  ] = useSwitchSessionMutation();

  const navigate = useNavigate();
  const location = useLocation();

  if (switchSessionData) {
    navigate(
      `/switch-session/${switchSessionData?.switchSession?.switchSessionRequest?.id}/status`,
    );
  }

  const handleAddGuardianship = () => {
    navigate("/add-guardianship", { state: { backgroundLocation: location } });
  };

  if (loading) {
    return null;
  }

  if (error || switchSessionError || !patient || !guardianships) {
    return <Typography>Error ...</Typography>;
  }

  return guardianships.length > 0 ? (
    <Box
      sx={{
        height: 30,
        width: "250px",
        padding: 0,
        margin: 0,
        display: "block",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <SwitchProfileButton
        defaultValue={patient?.id}
        disabled={switchSessionLoading}
        onChange={(event) => {
          if (typeof event.target.value !== "string") {
            console.warn("event.target.value is not a string");

            return;
          }

          const patientId = event.target.value;

          switchSession({
            variables: { patientId },
          });
        }}
      >
        <MenuItem
          key={guardian?.id ?? patient?.id}
          value={guardian?.id ?? patient?.id}
        >
          <Trans>My profile</Trans>
        </MenuItem>
        {guardianships.map((guardianship) => {
          const identifier = guardianship.dependantPatient?.identifier
            ? formatPatientIdentifier(guardianship.dependantPatient.identifier)
            : "";

          return (
            <MenuItem
              key={guardianship.id}
              value={guardianship.dependantPatient?.id}
            >
              {identifier}
            </MenuItem>
          );
        })}
        <MenuItem key={"Add another child"} onClick={handleAddGuardianship}>
          <Trans>Add another child</Trans>
        </MenuItem>
      </SwitchProfileButton>
    </Box>
  ) : null;
}

export { ProfileSelector };
