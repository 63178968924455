import { User } from "oidc-client-ts";
import { createContext, ReactNode } from "react";

import { useDefinedContext } from "../utils";

const UserContext = createContext<User | undefined>(undefined);

function useUser() {
  return useDefinedContext({
    context: UserContext,
    hookName: useUser.name,
    providerName: UserProvider.name,
  });
}

function UserProvider({ children, user }: { children: ReactNode; user: User }) {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export { UserProvider, useUser };
