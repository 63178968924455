import { useEffect } from "react";
import { FieldValues, Path, UseFormSetValue } from "react-hook-form";

interface FormPersistConfig<T extends FieldValues> {
  key: string;
  watch: (names?: string | string[]) => Record<string, unknown>;
  setValue: UseFormSetValue<T>;
}

function useFormPersist<T extends FieldValues>({
  key,
  watch,
  setValue,
}: FormPersistConfig<T>) {
  const data = watch();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }, [setValue, data, key]);

  useEffect(() => {
    const savedData = sessionStorage.getItem(key);
    const savedDataJson = savedData && JSON.parse(savedData);
    if (!savedDataJson) return;
    for (const value of Object.keys(savedDataJson)) {
      setValue(value as Path<T>, savedDataJson[value]);
    }
  }, [key, setValue]);
}

export { useFormPersist };
