import { User } from "oidc-client-ts";
import { useEffect, useState } from "react";

import { isFeatureEnabled } from "../feature-flags";
import { userManager } from "../user-manager";

type AuthLevel = (typeof AUTH_LEVELS)[number];

type AuthLevelResult = {
  authLevel: AuthLevel | undefined;
  loading: boolean;
  satisfiesAuthLevel: (requiredAuthLevel: AuthLevel) => boolean;
};

const AUTH_LEVELS = ["low", "medium", "high"] as const;

function getAuthLevel(user: User | null): AuthLevel | undefined {
  if (!user) {
    return undefined;
  }

  if (!isFeatureEnabled("authLevels")) {
    return "high";
  }

  switch (user.profile.identity_proofing_level) {
    case "P0":
      return "low";
    case "P5":
      return "medium";
    case "P9":
      return "high";
  }
}

function useAuthLevel(): AuthLevelResult {
  const [authLevel, setAuthLevel] = useState<AuthLevel | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userManager
      .getUser()
      .then((user) => setAuthLevel(getAuthLevel(user)))
      .finally(() => setLoading(false));
  }, []);

  const satisfiesAuthLevel = (requiredAuthLevel: AuthLevel) => {
    if (loading) {
      return false;
    }

    const userAuthLevelIndex = authLevel ? AUTH_LEVELS.indexOf(authLevel) : -1;
    const requiredAuthLevelIndex = AUTH_LEVELS.indexOf(requiredAuthLevel);
    return userAuthLevelIndex >= requiredAuthLevelIndex;
  };

  return { authLevel, loading, satisfiesAuthLevel };
}

export { useAuthLevel };
export type { AuthLevel };
