import { t, Trans } from "@lingui/macro";
import { Box, FormHelperText, FormLabel } from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import { PatientNotificationTypes } from "graphql-let/__generated__/__types__";
import { Control, Controller } from "react-hook-form";

import { Radio } from "../components/radio/radio";
import { colors } from "../theme";

export function NotificationTypeField({
  control,
  defaultValue,
  errorMessage,
}: {
  control: Control;
  defaultValue: Maybe<PatientNotificationTypes>;
  errorMessage: string | undefined;
}) {
  return (
    <Box sx={{ position: "relative" }}>
      <FormLabel
        sx={{
          backgroundColor: "white",
          color: colors.zymegoGreen,
          fontSize: "0.93rem",
          left: 0,
          margin: "0 0.5rem",
          padding: "0 0.3rem",
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          transform: "translateY(-50%) scale(.9)",
          transformOrigin: "left top",
        }}
      >
        <Trans>I want notifications by</Trans>
      </FormLabel>
      <Box
        sx={{
          border: "2px solid #E6E6E6",
          borderRadius: "6px",
          padding: "1rem 2rem",
        }}
      >
        <Controller
          control={control}
          defaultValue={defaultValue}
          name="notificationType"
          render={({ field }) => {
            return (
              <Radio.Group {...field} aria-label="Notification">
                <Radio label={t`SMS`} value="SMS" />
                <Radio label={t`Email`} value="EMAIL" />
              </Radio.Group>
            );
          }}
          rules={{ required: t`Notification type is required.` }}
        />
      </Box>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}
