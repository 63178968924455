import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";

import { ErrorComponent } from "../error";
import { Loading } from "../loading";
import { splitByComma } from "../utils";
import { useBookingTypes } from "./booking-types-context";
import { RevisitInfoDialog } from "./dialog-revisit-info";
import { useGetCareUnitsWithCaregiversQuery } from "./get-care-unit-with-caregivers.graphql";
import { useGetTimeslotsLazyQuery } from "./get-timeslots.graphql";
import { ListOfRadioButtons } from "./list-radio-buttons";

interface FormValues {
  id: string;
}

function SelectCaregiver() {
  const [searchParameters] = useSearchParams();
  const careUnitUrlFriendlyNames = splitByComma(
    searchParameters.get("clinicName"),
  );

  invariant(
    careUnitUrlFriendlyNames && careUnitUrlFriendlyNames.length > 0,
    "careUnit name(s) is not provided",
  );

  const caregiverIds = searchParameters.get("caregiverId");

  const requestId = searchParameters.get("requestId");

  invariant(requestId, "request Id is not provided");
  const { bookingTypes } = useBookingTypes();
  const [isRevisitInfoDialogOpen, setIsRevisitInfoDialogOpen] = useState(
    (!caregiverIds &&
      bookingTypes?.some((bookingType) => bookingType?.isRevisit === true)) ??
      false,
  );
  const bookingTypeIds = searchParameters.get("bookingTypeId");
  invariant(bookingTypeIds, "booking Type Id(s) is not provided");

  const earliestStartAt = searchParameters.get("earliestStartAt");
  const latestEndAt = searchParameters.get("latestEndAt");

  const parsedEarliestStartAt = useMemo(
    () => (earliestStartAt ? parseISO(earliestStartAt) : undefined),
    [earliestStartAt],
  );
  const parsedLatestEndAt = useMemo(
    () => (latestEndAt ? parseISO(latestEndAt) : undefined),
    [latestEndAt],
  );

  const navigate = useNavigate();
  const location = useLocation();

  const { control, handleSubmit } = useForm<FormValues>();

  const [hasTimedOut, setHasTimedOut] = useState(false);
  const timeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  const [
    getTimeslots,
    { loading: queryLoading, error: queryError, data: queryData },
  ] = useGetTimeslotsLazyQuery({
    fetchPolicy: "network-only",
    pollInterval: 1000 * 5,
  });

  const {
    error,
    loading,
    data: { careUnits } = {},
  } = useGetCareUnitsWithCaregiversQuery({
    variables: { careUnitUrlFriendlyNames },
  });

  useEffect(() => {
    getTimeslots({
      variables: {
        fetchTimeslotRequestId: requestId,
        bookingTypeIds: bookingTypes?.map(({ id }) => id) ?? [],
        careUnitIds: careUnits?.map((careUnit) => careUnit.id) ?? [],
        earliestStartAt: Number.isNaN(parsedEarliestStartAt?.getTime())
          ? undefined
          : parsedEarliestStartAt?.toISOString(),
        latestEndAt: Number.isNaN(parsedLatestEndAt?.getTime())
          ? undefined
          : parsedLatestEndAt?.toISOString(),
      },
    });

    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current);
    }

    timeoutHandle.current = setTimeout(
      () => setHasTimedOut(true),
      5 * 60 * 1000,
    );

    return () => clearTimeout(timeoutHandle.current);
  }, [
    getTimeslots,
    requestId,
    bookingTypeIds,
    setHasTimedOut,
    timeoutHandle,
    careUnits,
    parsedEarliestStartAt,
    parsedLatestEndAt,
    bookingTypes,
  ]);

  const onSubmit = ({ id: caregiverExternalId }: FormValues) => {
    const careGiverIds = careUnits
      ?.flatMap((careUnit) => careUnit.caregivers)
      .filter((caregiver) => caregiver.externalIdHash === caregiverExternalId)

      .map((caregiver) => caregiver.id)
      .join(",");

    const caregiverIdParameter =
      caregiverExternalId === "" ? "all" : careGiverIds;
    navigate({
      pathname: "../select-timeslot",
      search: `${location.search}&caregiverId=${caregiverIdParameter}`,
    });
  };

  // If parsed dates are invalid throw separate errors for each
  if (parsedEarliestStartAt && Number.isNaN(parsedEarliestStartAt.getTime())) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`Invalid earliest start date`}
        errorMessage={t`The provided earliest start date is invalid. Please check the url and update the date format of the earliest start date.`}
      />
    );
  }

  if (parsedLatestEndAt && Number.isNaN(parsedLatestEndAt.getTime())) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`Invalid latest end date`}
        errorMessage={t`The provided latest end date is invalid. Please check the url and update the date format of the latest end date.`}
      />
    );
  }

  if (!careUnits && loading) {
    return null;
  }

  const bookingType = _.uniq(bookingTypes?.map((type) => type.name));

  const caregivers = queryData?.timeslots?.map(({ caregiver }) => ({
    id: caregiver && caregiver.externalIdHash,
    name: caregiver && caregiver.name,
  }));

  const allCaregivers = careUnits
    ?.flatMap((careUnit) => careUnit.caregivers)
    .map((caregiver) => ({
      id: caregiver && caregiver.externalIdHash,
      name: caregiver && caregiver.name,
    }));

  const activeCaregivers = _.uniqBy(
    allCaregivers?.filter((caregiver) =>
      caregivers?.some((c) => caregiver.id === c.id),
    ),
    "id",
  );

  const inactiveCaregivers = _.uniqBy(
    allCaregivers?.filter(
      (caregiver) =>
        !activeCaregivers.some(
          (activeCaregiver) => activeCaregiver.id === caregiver.id,
        ),
    ),
    "id",
  );

  if (caregiverIds) {
    return <Navigate to={`../select-timeslot${location.search}`} />;
  }

  if (
    loading ||
    queryLoading ||
    (queryData &&
      queryData.fetchTimeslotsRequests &&
      queryData.fetchTimeslotsRequests.some((request) =>
        ["QUEUED", "IN_PROGRESS"].includes(request.status),
      ))
  )
    return (
      <Loading
        logo={false}
        text={t`We are fetching available time slots from the patient record system. This may take a while.`}
      />
    );

  if (!careUnits || error || queryError)
    return <ErrorComponent component="new-booking-error" />;

  if ((!activeCaregivers || activeCaregivers.length <= 0) && !loading) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`There are no available time slots right now
`}
        errorMessage={careUnits
          .map(
            (unit) =>
              t`Please contact ${unit.name}. You will find contact details on the homepage: ${unit.externalHomePageUrl}.`,
          )
          .join("\n")}
      />
    );
  }
  if (
    queryData?.fetchTimeslotsRequests?.some(
      (request) => request.status === "FAILED",
    ) ||
    hasTimedOut
  ) {
    return <ErrorComponent component="new-booking-error" />;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ flex: "auto", display: "flex" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={1}
          sx={{
            paddingX: {
              xs: 2,
              sm: 3,
            },
          }}
          textAlign="center"
        >
          <Typography fontSize="15px" fontWeight={700} marginBottom={2}>
            <Trans>Select caregiver and click “Next” to continue</Trans>
          </Typography>

          <Typography fontSize="15px" fontWeight={400}>
            {bookingType}
          </Typography>

          <ListOfRadioButtons
            disabledProperties={inactiveCaregivers}
            formProperties={{ control }}
            properties={activeCaregivers}
            selectAll={
              !bookingTypes?.some(
                ({ newAppointmentHideAllCaregiversOption }) =>
                  newAppointmentHideAllCaregiversOption,
              )
            }
          />
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 10,
            }}
          >
            <Box
              sx={{
                margin: "auto",
                maxWidth: "444px",
                textAlign: "center",
                background: "white",
                boxShadow: "0 -40px 40px white",
              }}
            >
              <Box
                sx={{
                  paddingX: {
                    xs: 1,
                    sm: 2,
                  },
                }}
              >
                <Button
                  disabled={
                    loading ||
                    careUnits.flatMap((careUnit) => careUnit.caregivers)
                      .length === 0
                  }
                  endIcon={<ArrowForward />}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  <Trans>Next</Trans>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
      <RevisitInfoDialog
        isOpen={isRevisitInfoDialogOpen}
        onConfirm={() => setIsRevisitInfoDialogOpen(false)}
      />
    </>
  );
}

export { SelectCaregiver };
