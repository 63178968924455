query GetProfileSelectorData {
  guardian: currentGuardianPatient {
    id
    identifier
  }
  patient: currentPatient {
    id
    identifier
  }
  guardianships {
    id
    dependantPatient {
      id
      identifier
    }
  }
}
