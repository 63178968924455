import Plausible, { EventOptions, PlausibleOptions } from "plausible-tracker";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

let plausibleOptions = {};

if (import.meta.env.DEV) {
  plausibleOptions = {
    ...plausibleOptions,
    domain: "dev.zymego.app",
    trackLocalhost: true,
  };
}

const { trackEvent, trackPageview } = Plausible(plausibleOptions);

// RegExp for /UUID
const regexp =
  /\/[\da-f]{8}-[\da-f]{4}-[0-5][\da-f]{3}-[089ab][\da-f]{3}-[\da-f]{12}/gi;

function removeUUIDsFromUrl(url: string) {
  return url.replaceAll(regexp, "");
}

function Analytics() {
  const location = useLocation();

  useEffect(() => {
    // Remove /UUID from URL before sending to plausible
    const url = removeUUIDsFromUrl(globalThis.location.href);

    trackPageview({ url });
  }, [location]);
  return null;
}

// eslint-disable-next-line max-params
function trackEventWrapper(
  eventName: string,
  options?: EventOptions,
  eventData?: PlausibleOptions,
): void {
  const url = removeUUIDsFromUrl(globalThis.location.href);

  return trackEvent(eventName, options, { ...eventData, url });
}

export {
  Analytics,
  removeUUIDsFromUrl,
  trackEventWrapper as trackEvent,
  trackPageview,
};
