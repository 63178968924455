query GetRescheduleAppointmentStatus($rescheduleAppointmentRequestId: UUID!) {
  rescheduleAppointmentRequest(id: $rescheduleAppointmentRequestId) {
    id
    careUnitId
    careUnit {
      id
      name
    }
    status
    appointment {
      id
      caregiverId
      startAtInCareUnitsTimezone
      caregiver {
        id
        name
      }
      careUnit {
        id
        address
        postAddress
        postCode
        name
      }
    }
    fromWaitlist
    fromWishlist
  }
  # patientAppointments is a hack, see comment on app_public.patient_appointments
  appointments: patientAppointments {
    id
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    caregiver {
      id
      name
    }
    careUnit {
      id
      address
      postAddress
      postCode
      name
    }
  }
}
