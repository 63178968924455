import { isNonEmptyArray } from "@apollo/client/utilities";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { AccessDeniedView } from "../auth/access-denied-view";
import { useInactivitySignout } from "../auth/use-inactivity-signout";
import { useSessionExpirySignout } from "../auth/use-session-expiry-signout";
import { ErrorComponent } from "../error";
import { useGetCareUnitsWithCaregiversQuery } from "../get-care-units-with-caregivers.graphql";
import { NewAppointmentLayout } from "../new-appointment-layout";
import { NhsSignInPage } from "../nhs/sign-in-page";
import { useAuthLevel } from "../nhs/user-auth-level";
import { getConfig } from "../runtime-config";
import { AppUser, AppUserState } from "../user/app-user";
import { UserProvider } from "../user/user-context";
import { splitByComma } from "../utils";
import { ManageAppointmentsApp } from "./manage-appointments-app";
import { NewAppointmentApp } from "./new-appointment-app";

const { AUTH_IDENTITY_PROVIDER } = getConfig();

function AppSelector({
  state,
  user,
}: {
  state: AppUserState | undefined;
  user: AppUser | undefined;
}) {
  useInactivitySignout();
  useSessionExpirySignout();

  const { loading: isAuthLevelLoading, satisfiesAuthLevel } = useAuthLevel();
  const [searchParameters] = useSearchParams();

  const clinicName = searchParameters.get("clinicName");
  const careUnitUrlFriendlyNames = splitByComma(clinicName);
  const hasCareUnit = isNonEmptyArray(careUnitUrlFriendlyNames);

  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading, error } = useGetCareUnitsWithCaregiversQuery({
    variables: { careUnitUrlFriendlyNames },
    skip: !hasCareUnit,
  });

  useEffect(() => {
    if (
      !loading &&
      hasCareUnit &&
      !data?.careUnits &&
      location.pathname !== "/"
    ) {
      navigate("/");
    }
  }, [data, hasCareUnit, loading, location, navigate]);

  if (hasCareUnit) {
    if (
      AUTH_IDENTITY_PROVIDER === "nhs" &&
      !isAuthLevelLoading &&
      !satisfiesAuthLevel("medium")
    ) {
      return <NhsSignInPage />;
    }

    if (error) {
      return (
        <NewAppointmentLayout>
          <ErrorComponent component="new-booking-error" />
        </NewAppointmentLayout>
      );
    }
    if (loading && !data) return null;

    if (data?.careUnits) {
      return <NewAppointmentApp />;
    }
  }

  if (!user) {
    return <AccessDeniedView state={state} />;
  }

  return (
    <UserProvider user={user}>
      <ManageAppointmentsApp />
    </UserProvider>
  );
}

export { AppSelector };
