query GetCareUnitsWithCaregivers($careUnitUrlFriendlyNames: [String!]!) {
  careUnits(filter: { urlFriendlyName: { in: $careUnitUrlFriendlyNames } }) {
    id
    name
    externalHomePageUrl
    caregivers {
      id
      name
    }
  }
}
