import { t } from "@lingui/macro";
import { createContext, ReactNode, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import { ErrorComponent } from "../error";
import { Loading } from "../loading";
import { splitByComma } from "../utils";
import {
  GetBookingTypesQuery,
  useGetBookingTypesQuery,
} from "./get-booking-types.graphql";

interface BookingTypesProviderProperties {
  children: ReactNode;
}

const BookingTypeContext = createContext<GetBookingTypesQuery>({
  bookingTypes: [],
});

function BookingTypesProvider({ children }: BookingTypesProviderProperties) {
  const [searchParameters] = useSearchParams();
  const bookingTypeIds = searchParameters.get("bookingTypeId");

  const {
    loading: isLoading,
    data,
    error,
  } = useGetBookingTypesQuery({
    variables: { bookingTypeIds: splitByComma(bookingTypeIds) },
    skip: !bookingTypeIds,
    fetchPolicy: "network-only",
  });

  const bookingTypes = data?.bookingTypes ?? [];

  if (isLoading) {
    <Loading logo={false} text={t`Loading bookings...`} />;
  }

  if (error && bookingTypeIds) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`Invalid booking type`}
        errorMessage={t`The booking type you are trying to book is invalid. Please contact the clinic for more information.`}
      />
    );
  }

  return (
    <BookingTypeContext.Provider value={{ bookingTypes }}>
      {children}
    </BookingTypeContext.Provider>
  );
}

function useBookingTypes() {
  return useContext(BookingTypeContext);
}

export { BookingTypesProvider, useBookingTypes };
