/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { t } from "@lingui/macro";
import { Box, Button, ToggleButton, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Control, Controller, UseFormGetValues } from "react-hook-form";

import { GetTimeslotsQuery } from "./new-appointment/get-timeslots.graphql";
import { colors } from "./theme";
import { GroupedTimeslots } from "./utils";

type Timeslot = NonNullable<GetTimeslotsQuery["timeslots"]>[number];

function RescheduleTimeTable({
  timeslots,
  formProperties,
  colorMap,
}: {
  formProperties: {
    control: Control<{ id: string }>;
    getValues: UseFormGetValues<{ id: string }>;
  };
  timeslots: Timeslot[];
  colorMap?: Map<string, string>;
}) {
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot>();

  useEffect(() => {
    if (selectedTimeslot && !timeslots?.includes(selectedTimeslot)) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setSelectedTimeslot(undefined);
    }
  }, [selectedTimeslot, timeslots]);

  // Group timeslots so we can easily loop through them in the jsx

  const groupedTimeslots: GroupedTimeslots<Timeslot> = {};

  for (const timeslot of timeslots) {
    const date = new Date(timeslot.startAtInCareUnitsTimezone);
    const month = format(date, "y-M");
    const day = format(date, "d");
    const time = format(date, "k:mm");

    // Set months
    if (!groupedTimeslots[month]) {
      groupedTimeslots[month] = {
        items: {},
        date: date,
      };
    }
    // Set days
    if (!groupedTimeslots[month]!["items"][day]) {
      groupedTimeslots[month]!["items"][day] = {
        items: {},
        date: date,
      };
    }
    // Set times
    groupedTimeslots[month]!["items"][day]!["items"][time] = {
      timeslot,
      date: date,
    };
  }

  return (
    <Box>
      {Object.keys(groupedTimeslots).map((month) => (
        <Box key={month} sx={{ position: "relative", overflowX: "visible" }}>
          <Box
            key={month}
            sx={{
              background: "white",
              zIndex: "1",
              position: "sticky",
              top: 0,
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              padding: {
                xs: "8px 16px",
                sm: "8px 16px",
              },
            }}
          >
            <Typography
              sx={{
                color: colors.zymegoGreen,
                ":first-letter": {
                  textTransform: "uppercase",
                },
              }}
              variant="h6"
            >
              {format(groupedTimeslots[month]!.date, "MMMM")}
            </Typography>
            <Typography
              sx={{ color: colors.zymegoGreen, opacity: 0.35 }}
              variant="h6"
            >
              {format(groupedTimeslots[month]!.date, "y")}
            </Typography>
          </Box>
          {Object.keys(groupedTimeslots[month]!.items).map((day) => (
            <Box
              key={day}
              sx={{
                display: "flex",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                textAlign: "start",
              }}
            >
              <Box
                sx={{
                  width: "65px",
                  flexShrink: "0",
                  padding: "8px 16px 8px 16px",
                  ":first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                <Typography sx={{ color: colors.zymegoGreen }} variant="h6">
                  {format(groupedTimeslots[month]!.items[day]!.date, "E")}
                </Typography>
                <Typography sx={{ color: colors.zymegoGreen }} variant="h6">
                  {format(groupedTimeslots[month]!.items[day]!.date, "d")}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "12px 0 0 12px",
                }}
              >
                <Controller
                  control={formProperties.control}
                  defaultValue={selectedTimeslot?.id}
                  name="id"
                  render={({ field }) => {
                    return (
                      <Box>
                        {Object.keys(
                          groupedTimeslots[month]!.items[day]!.items,
                        ).map((timeslot) => {
                          const careUnitId =
                            groupedTimeslots[month]!.items[day]!.items[
                              timeslot
                            ]!.timeslot?.careUnit?.id;
                          const backgroundColor = colorMap?.get(careUnitId!);
                          return (
                            <ToggleButton
                              key={timeslot}
                              {...field}
                              component={Button}
                              selected={
                                formProperties.getValues("id") ===
                                groupedTimeslots[month]!.items[day]!.items[
                                  timeslot
                                ]!.timeslot?.id
                              }
                              sx={{
                                marginX: "8px",
                                marginBottom: "8px",
                                width: "65px",
                                fontSize: "15px",
                                fontWeight: "700",
                                lineHeight: "22px",
                                ...(backgroundColor && {
                                  backgroundColor: `${backgroundColor} !important`,
                                }),
                                // Undo hover effect on touch devices:
                                "@media (hover: none)": {
                                  "&:hover": {
                                    backgroundColor: colors.zymegoSpearMint,
                                  },
                                },
                              }}
                              type="button"
                              value={
                                groupedTimeslots[month]!.items[day]!.items[
                                  timeslot
                                ]!.timeslot.id
                              }
                            >
                              {format(
                                groupedTimeslots[month]!.items[day]!.items[
                                  timeslot
                                ]!.date,
                                "k:mm",
                              )}
                            </ToggleButton>
                          );
                        })}
                      </Box>
                    );
                  }}
                  rules={{
                    required: t`Please select one of these options`,
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
export { RescheduleTimeTable };
