import { t, Trans } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import { normalise, parse } from "personnummer.js";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useCreateProfileMutation } from "./create-profile.graphql";

interface FormValues {
  patientIdentifier: string;
}

function AddGuardianshipDialog({
  careUnitUrlFriendlyName,
}: {
  careUnitUrlFriendlyName?: string;
}) {
  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const location = useLocation();
  const previousLocation = location.pathname.slice(
    0,
    location.pathname.lastIndexOf("/"),
  );
  const [createProfile, { data, error, loading }] = useCreateProfileMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  if (data) {
    navigate(
      previousLocation +
        `/guardianship/${data?.createProfile?.validateAndCreateGuardianSessionRequest?.id}/status` +
        location.search,
    );
  }

  const onSubmit = (formValues: FormValues) => {
    const normalizedPatientIdentifier = normalise(formValues.patientIdentifier);

    if (normalizedPatientIdentifier === undefined) {
      return;
    }

    createProfile({
      variables: {
        patientIdentifier: normalizedPatientIdentifier,
        ...(careUnitUrlFriendlyName ? { careUnitUrlFriendlyName } : undefined),
      },
      refetchQueries: "active",
    });
  };

  return (
    <DialogBottom
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(
          { pathname: backgroundLocation.pathname, search: location.search },
          { replace: true },
        );
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          padding: 1,
          display: "inline-flex",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box padding={{ sm: 3, sx: 2 }}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Typography fontSize="22px" fontWeight="700" textAlign="center">
            <Trans>Enter the child's personal identity number</Trans>
          </Typography>
          <Controller
            control={control}
            defaultValue=""
            name="patientIdentifier"
            render={({ field }) => (
              <TextField
                placeholder={t`YYYYMMDD-XXXX`}
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.patientIdentifier)}
                fullWidth={true}
                helperText={errors.patientIdentifier?.message}
                label={t`Personal identity number`}
                size="medium"
              />
            )}
            rules={{
              validate(value) {
                if (value?.length === 0) {
                  return t`Personal identity number is required.`;
                }

                const parsedPatientIdentifier = parse(value);

                if (parsedPatientIdentifier.valid === false) {
                  return t`Please provide a valid personal identity number.`;
                }

                if (
                  parsedPatientIdentifier.age &&
                  parsedPatientIdentifier.age >= 16
                ) {
                  return t`The child must be under the age of 16.`;
                }

                return true;
              },
            }}
          />
          {error ? (
            <Alert severity="error">
              <Trans>Guardianship could not be validated.</Trans>
            </Alert>
          ) : null}
          <LoadingButton
            endIcon={
              theme.direction === "ltr" ? (
                <ArrowRight style={{ fill: "currentColor" }} />
              ) : (
                <ArrowLeft style={{ fill: "currentColor" }} />
              )
            }
            fullWidth
            loading={loading}
            loadingPosition="end"
            type="submit"
            variant="contained"
          >
            <Trans>Confirm</Trans>
          </LoadingButton>
        </form>
      </Box>
    </DialogBottom>
  );
}
export { AddGuardianshipDialog };
