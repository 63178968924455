mutation UpdatePatient(
  $consentForConfirmationsAndReminders: Boolean
  $email: String!
  $notificationType: PatientNotificationTypes!
  $patientId: UUID!
  $phoneNumber: String
) {
  updatePatient(
    input: {
      id: $patientId
      patch: {
        consentForConfirmationsAndReminders: $consentForConfirmationsAndReminders
        notificationType: $notificationType
        email: $email
        phoneNumber: $phoneNumber
      }
    }
  ) {
    patient {
      id
      consentForConfirmationsAndReminders
      notificationType
      email
      phoneNumber
    }
  }
}
