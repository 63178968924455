import { apm } from "@elastic/apm-rum";
import { Trans } from "@lingui/macro";
import { Button, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { Layout } from "./layout";
import { NewBookingError } from "./new-appointment/new-booking-error";
import { newAppointmentFlowByLocation } from "./utils";

function ErrorComponent({
  errorHeader,
  errorMessage,
  component,
  inline,
  error,
}: {
  /**
   * A string that will be displayed in the header of the component for the new booking error
   * template.
   */
  errorHeader?: string;
  /**
   * A string that will be displayed in the body of the component.
   */
  errorMessage?: string;
  /**
   * A string defining a custom component to be used instead of the main one. The value should be
   * the same as the file name of the imported error component. Currently supports only the value
   * 'new-booking-error'.
   */
  component?: string;
  /**
   * Set to true if we want to component to appear as part of the existing page view, otherwise
   * appears as a standalone page.
   */
  inline?: boolean;
  /**
   * If inline is true or template has a value and we also want to capture a custom error in Elastic
   * provide a js Error here. Should not be confused with errorMessage.
   */
  error?: Error;
}) {
  if (error && (inline || component)) {
    apm.captureError(error);
  }

  const backgroundLocation = useLocation();

  const handleRefresh = () =>
    (window.location.href = newAppointmentFlowByLocation(backgroundLocation)
      ? "/bookings/new" + backgroundLocation.search
      : "/");

  const errorOutput =
    component && component === "new-booking-error" ? (
      <NewBookingError
        bodyTextMessage={errorMessage}
        headerTextMessage={errorHeader}
      />
    ) : inline ? (
      <Grid
        alignItems="stretch"
        container
        direction="column"
        flexGrow={1}
        sx={{
          padding: 3,
        }}
      >
        <Grid item sx={{ textAlign: "center" }}>
          <Typography align="center" sx={{ marginBottom: 2 }} variant="h1">
            <Trans>Something went wrong</Trans>
          </Typography>
          {Boolean(errorMessage) && (
            <Typography align="center">{errorMessage}</Typography>
          )}
          <Typography align="center" sx={{ marginBottom: 4 }}>
            <Trans>
              Please reload the page to go back to the starting page and try
              again.
            </Trans>
          </Typography>
          <Typography align="center" sx={{ marginBottom: 4 }}>
            <Trans>
              If it still does not work,{" "}
              <Link
                component={RouterLink}
                state={{ backgroundLocation }}
                to="/contact"
              >
                contact us
              </Link>
              .
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            fullWidth
            onClick={handleRefresh}
            variant="contained"
          >
            <Trans>Back to start</Trans>
          </Button>
        </Grid>
      </Grid>
    ) : (
      <Layout>
        <Grid
          container
          direction="column"
          flexGrow={1}
          sx={{
            padding: 3,
          }}
        >
          <Grid item sx={{ textAlign: "center" }}>
            <Typography align="center" sx={{ marginBottom: 2 }} variant="h1">
              <Trans>Something went wrong</Trans>
            </Typography>
            {Boolean(errorMessage) && (
              <Typography align="center">{errorMessage}</Typography>
            )}
            <Typography align="center" sx={{ marginBottom: 4 }}>
              <Trans>
                Please reload the page to go back to the starting page and try
                again.
              </Trans>
            </Typography>
            <Typography align="center" sx={{ marginBottom: 4 }}>
              <Trans>
                If it still does not work,{" "}
                <Link
                  component={RouterLink}
                  state={{ backgroundLocation }}
                  to="/contact"
                >
                  contact us
                </Link>
                .
              </Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              fullWidth
              onClick={handleRefresh}
              variant="contained"
            >
              <Trans>Refresh</Trans>
            </Button>
          </Grid>
        </Grid>
      </Layout>
    );
  return errorOutput;
}

/**
 * ErrorComponentSimple
 *
 * This component is used by the error boundary when the application is in a state
 * where it cannot reliably access routing capabilities. It provides a fallback
 * UI that displays a friendly error message to the user, suggesting actions they
 * can take (such as refreshing the page or email the support). This is essential for maintaining a
 * user experience even when the application encounters critical errors and
 * cannot proceed with normal routing and navigation.
 */
function ErrorComponentSimple({
  errorMessage,
  caughtError,
}: {
  /**
   * A string that will be displayed in the body of the component.
   */
  errorMessage?: string;
  /**
   * If inline is true or template has a value and we also want to capture a custom error in Elastic
   * provide a js Error here.
   */
  caughtError?: Error;
}) {
  if (caughtError) {
    apm.captureError(caughtError);
  }

  const backgroundLocation = useLocation();

  const handleRefresh = () =>
    (window.location.href = newAppointmentFlowByLocation(backgroundLocation)
      ? "/bookings/new" + backgroundLocation.search
      : "/");

  const errorOutput = (
    <Layout>
      <Grid
        container
        direction="column"
        flexGrow={1}
        sx={{
          padding: 3,
        }}
      >
        <Grid item sx={{ textAlign: "center" }}>
          <Typography align="center" sx={{ marginBottom: 2 }} variant="h1">
            <Trans>Something went wrong</Trans>
          </Typography>
          {Boolean(errorMessage) && (
            <Typography align="center">{errorMessage}</Typography>
          )}
          <Typography align="center" sx={{ marginBottom: 4 }}>
            <Trans>
              Please reload the page to go back to the starting page and try
              again.
            </Trans>
          </Typography>
          <Typography align="center" sx={{ marginBottom: 4 }}>
            <Trans>
              If it still does not work, email{" "}
              <a href="mailto:support@zymego.com">support@zymego.com</a>.
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            fullWidth
            onClick={handleRefresh}
            variant="contained"
          >
            <Trans>Refresh</Trans>
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
  return errorOutput;
}

export { ErrorComponent, ErrorComponentSimple };
