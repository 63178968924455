import { i18n } from "@lingui/core";
import { detect, fromNavigator, fromStorage } from "@lingui/detect-locale";
import { I18nProvider, useLingui } from "@lingui/react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocalStorageValue } from "@react-hookz/web";
import {
  ar as pluralsAr,
  cy as pluralsCy,
  en as pluralsEn,
  sv as pluralsSv,
} from "make-plural/plurals";
import { PropsWithChildren } from "react";

import { getGeographyConfig } from "./geographies";
import { ReactComponent as ArabicFlag } from "./icons/arabic-flag.svg";
import { ReactComponent as EnglishFlag } from "./icons/english-flag.svg";
import { ReactComponent as SwedishFlag } from "./icons/swedish-flag.svg";
import { ReactComponent as WelshFlag } from "./icons/welsh-flag.svg";
import { setupDateFnsLocale } from "./locale-date-fns";
import { messages as arabicMessages } from "./locales/ar/messages.mjs";
import { messages as welshMessages } from "./locales/cy/messages.mjs";
import { messages as englishMessages } from "./locales/en/messages.mjs";
import { messages as swedishMessages } from "./locales/sv/messages.mjs";

const locales = {
  ar: {
    flag: ArabicFlag,
    text: "عربي",
  },
  cy: {
    flag: WelshFlag,
    text: "Cymraeg",
  },
  en: {
    flag: EnglishFlag,
    text: "English",
  },
  sv: {
    flag: SwedishFlag,
    text: "Svenska",
  },
};

i18n.load({
  ar: arabicMessages,
  cy: welshMessages,
  en: englishMessages,
  sv: swedishMessages,
});

i18n.loadLocaleData({
  ar: { plurals: pluralsAr },
  cy: { plurals: pluralsCy },
  en: { plurals: pluralsEn },
  sv: { plurals: pluralsSv },
});

i18n.on("change", async () => {
  setupDateFnsLocale(i18n.locale);
});

const detectedLocale = detect(fromStorage("lang"), fromNavigator());

const [parsedDetectedLocale] = detectedLocale?.split(/[_-]/) ?? [];

i18n.activate(parsedDetectedLocale ?? "en");

function LocalizedApp({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  // @ts-expect-error TODO: Fix mismatching `@lingui/core` versions.
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
}

function LanguageSelector() {
  const { i18n: i18nFromContext } = useLingui();

  const { set: setLocale, value: currentLocale } = useLocalStorageValue(
    "lang",
    {
      defaultValue: i18nFromContext.locale,
      stringify(value) {
        if (typeof value === "string") {
          return value;
        }
        return null;
      },
      // eslint-disable-next-line max-params
      parse(value, fallback) {
        return value ?? fallback;
      },
    },
  );

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLocale = event.target.value;

    i18nFromContext.activate(selectedLocale);
    setLocale(selectedLocale);
  };

  const geographyConfig = getGeographyConfig();

  return (
    <FormControl
      sx={{
        height: 35,
        textAlign: "center",
      }}
    >
      <Select
        data-cy="language-selector"
        defaultValue={currentLocale}
        disableUnderline
        displayEmpty
        id="demo-simple-select"
        label="languagePicker"
        labelId="demo-simple-select-label"
        onChange={handleChange}
        sx={{ marginTop: 0 }}
        variant="standard"
      >
        {geographyConfig.locales.map((locale) => {
          const Flag = locales[locale].flag;

          return (
            <MenuItem
              data-cy={`language-select-option-${locale}`}
              key={locale}
              value={locale}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Flag />
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export { LanguageSelector, LocalizedApp };
