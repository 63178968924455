query GetCurrentPatientAndGuardianships {
  currentPatient {
    id
    identifier
  }

  guardianships {
    id
    dependantPatient {
      id
      identifier
    }
  }
}
