mutation UpdatePatientNotificationType(
  $id: UUID!
  $notificationType: PatientNotificationTypes!
) {
  updatePatient(
    input: { id: $id, patch: { notificationType: $notificationType } }
  ) {
    patient {
      id
      notificationType
    }
  }
}
