import { CountryCode } from "libphonenumber-js";

import { getConfig, GetConfigOptions } from "./runtime-config";

type Geography = (typeof GEOGRAPHIES)[number];

type GeographyConfig = {
  countryCode: CountryCode;
  defaultLocale: LocaleName;
  enabledFeatures: {
    authLevels: boolean;
    lateAppointmentChanges: boolean;
  };
  locales: LocaleName[];
  signoutTimers?: {
    inactivitySeconds?: number;
    sessionExpirySeconds?: number;
  };
};

type LocaleName = "ar" | "cy" | "en" | "sv";

const GEOGRAPHIES = ["SE", "UK"] as const;

const geographyConfigs: Record<Geography, GeographyConfig> = {
  SE: {
    countryCode: "SE",
    defaultLocale: "sv",
    enabledFeatures: {
      authLevels: false,
      lateAppointmentChanges: true,
    },
    locales: ["ar", "en", "sv"],
  },
  UK: {
    countryCode: "GB",
    defaultLocale: "en",
    enabledFeatures: {
      authLevels: true,
      lateAppointmentChanges: false,
    },
    locales: ["en", "cy"],
    /**
     * From {@link https://nhsconnect.github.io/nhslogin/session-management/}:
     *
     * "The user must complete a full authentication journey:
     * * after 30 minutes of inactivity within the application
     * * after 12 hours of continuous usage"
     */
    signoutTimers: {
      inactivitySeconds: 30 * 60,
      sessionExpirySeconds: 12 * 60 * 60,
    },
  },
};

const getGeographyConfig = (options?: GetConfigOptions): GeographyConfig => {
  const { GEOGRAPHY } = getConfig(options);
  return geographyConfigs[GEOGRAPHY];
};

export { GEOGRAPHIES, getGeographyConfig };
export type { Geography, GeographyConfig, LocaleName };
