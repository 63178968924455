import { Localization } from "@mui/material/locale";

export const localizationCyGB: Localization = {
  components: {
    MuiAlert: {
      defaultProps: {
        closeText: "Close",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        clearText: "Clear",
        closeText: "Close",
        loadingText: "Loading…",
        noOptionsText: "No options",
        openText: "Open",
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        expandText: "Show path",
      },
    },
    MuiPagination: {
      defaultProps: {
        "aria-label": "Pagination navigation",
        // eslint-disable-next-line max-params
        getItemAriaLabel: (type, page, selected) => {
          if (type === "page") {
            return `${selected ? "" : "Go to "}page ${page}`;
          }
          if (type === "first") {
            return "Go to first page";
          }
          if (type === "last") {
            return "Go to last page";
          }
          if (type === "next") {
            return "Go to next page";
          }
          return "Go to previous page";
        },
      },
    },
    MuiRating: {
      defaultProps: {
        emptyLabelText: "Empty",
        getLabelText: (value) => {
          return `${value} Star${value !== 1 ? "s" : ""}`;
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        getItemAriaLabel: (type) => {
          if (type === "first") {
            return "Go to first page";
          }
          if (type === "last") {
            return "Go to last page";
          }
          if (type === "next") {
            return "Go to next page";
          }
          return "Go to previous page";
        },
        labelDisplayedRows: ({ from, to, count }) => {
          return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
        },
        labelRowsPerPage: "Rows per page:",
      },
    },
  },
};
