import { t, Trans } from "@lingui/macro";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { CancelAppointmentRequestStatus } from "graphql-let/__generated__/__types__";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { trackEvent } from "../analytics";
import { DialogBottom } from "../dialog-bottom";
import { ErrorComponent } from "../error";
import { ReactComponent as ThumbsUp } from "../icons/thumbs-up.svg";
import { ReactComponent as TwoArrows } from "../icons/two-arrows.svg";
import { Loading } from "../loading";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors } from "../theme";
import { useGetCancelAppointmentStatusQuery } from "./fetch-cancel-request-status.graphql";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function CancellationStatus() {
  const backgroundLocation = useBackgroundLocation();
  const { cancelAppointmentRequestId, appointmentId = "" } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const navigate = useNavigate();
  invariant(
    cancelAppointmentRequestId,
    "cancelAppointmentRequestId is missing from the URL",
  );
  const { data: { appointment } = {} } = useGetAppointmentQuery({
    variables: { id: appointmentId },
  });

  const { loading, error, data, stopPolling } =
    useGetCancelAppointmentStatusQuery({
      variables: { cancelAppointmentRequestId },
      pollInterval: 200,
    });

  useEffect(() => {
    if (data?.cancelAppointmentRequest?.status === "SUCCESS") {
      trackEvent("cancel-appointment", {
        props: {
          careUnitId: data.cancelAppointmentRequest.careUnitId,
          careUnitName: data.cancelAppointmentRequest.careUnit?.name ?? "",
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      data?.cancelAppointmentRequest &&
      (["SUCCESS", "FAILED"] as CancelAppointmentRequestStatus[]).includes(
        data.cancelAppointmentRequest.status,
      )
    ) {
      stopPolling();
    }
  }, [stopPolling, data]);

  let message = t`Please wait while processing your request`;

  if (data?.cancelAppointmentRequest?.status === "SUCCESS") {
    message = t`Your appointment has been cancelled`;
  } else if (error || data?.cancelAppointmentRequest?.status === "FAILED") {
    return (
      <DialogBottom
        onClose={() => setIsDialogOpen(false)}
        onExited={() => {
          navigate(backgroundLocation.pathname, { replace: true });
        }}
        open={isDialogOpen}
      >
        <Box
          sx={{
            paddingTop: 2,
            paddingX: 2,
            paddingBottom: 1,
            display: "inline-flex",
            color: colors.blue,
            textAlign: "center",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          <MuiLink
            color={colors.blue}
            onClick={() => setIsDialogOpen(false)}
            underline="none"
          >
            <Trans>Close</Trans>
          </MuiLink>
        </Box>
        <ErrorComponent
          errorMessage={t`Something went wrong while cancelling your appointment, please try again later`}
          inline
        />
      </DialogBottom>
    );
  }
  if (loading) {
    return null;
  }

  return (
    <DialogBottom
      onClose={() => setIsDialogOpen(false)}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          data-cy="appointment-cancelled-close-button"
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box sx={{ padding: { sm: 3, xs: 2 } }}>
        <Typography
          align="center"
          data-cy="appointment-cancelled-success-message"
          variant="h4"
        >
          {message}
        </Typography>

        {data?.cancelAppointmentRequest?.status === "IN_PROGRESS" ? (
          <Box sx={{ padding: 4 }}>
            <Loading logo={false} text={t`Cancelling appointment`} />
          </Box>
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Box sx={{ paddingX: 4 }}>
              <ThumbsUp height={150} />
            </Box>
            {appointment?.onWishlist ? (
              <Box
                sx={{
                  margin: "auto",
                  display: "inline-flex",
                  alignItems: "center",
                  paddingX: 2,
                  paddingBottom: 2,
                  textAlign: "start",
                  gap: "24px",
                }}
              >
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    color: colors.zymegoWarmGreen,
                    backgroundColor: colors.zymegoSpearMint,
                  }}
                >
                  <TwoArrows style={{ fill: "currentColor" }} />
                </Avatar>
                <Trans>
                  If a time that suits you becomes available, we will send a
                  booking confirmation.
                </Trans>
              </Box>
            ) : undefined}

            <Button component={Link} fullWidth to="/" variant="contained">
              <Trans>Close</Trans>
            </Button>
          </Box>
        )}
      </Box>
    </DialogBottom>
  );
}
export { CancellationStatus };
