import { t, Trans } from "@lingui/macro";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { trackEvent } from "../analytics";
import { useGetCancelAppointmentStatusQuery } from "../cancel/fetch-cancel-request-status.graphql";
import { Button } from "../components/button/button";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { ReactComponent as TwoArrows } from "../icons/two-arrows.svg";
import { Loading } from "../loading";
import { colors, theme } from "../theme";
import { useGetAppointmentQuery } from "./get-appointment.graphql";
import { useLeaveWishlistMutation } from "./leave-wishlist.graphql";

function WishListStatus() {
  const { formatDate, formatTime } = useDateFormatter();
  const { appointmentId: id = "", cancelAppointmentRequestId = "" } =
    useParams();

  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
    stopPolling,
  } = useGetCancelAppointmentStatusQuery({
    variables: { cancelAppointmentRequestId },
    pollInterval: 200,
    skip: !cancelAppointmentRequestId,
  });

  const [leaveWishlist] = useLeaveWishlistMutation();

  const noCancellationRequest = cancelAppointmentRequestId === "";

  const isCancelled =
    statusData?.cancelAppointmentRequest?.status === "SUCCESS";

  const cancellationFailed =
    statusData?.cancelAppointmentRequest?.status === "FAILED";

  const {
    loading,

    error,
    data: { appointment } = {},
  } = useGetAppointmentQuery({
    variables: { id },
  });

  useEffect(() => {
    if (isCancelled && statusData?.cancelAppointmentRequest) {
      trackEvent("cancel-appointment", {
        props: {
          careUnitId: statusData.cancelAppointmentRequest.careUnitId,
          careUnitName:
            statusData.cancelAppointmentRequest.careUnit?.name ?? "",
        },
      });
    }
  }, [isCancelled, statusData]);

  useEffect(() => {
    if (cancellationFailed && appointment) {
      leaveWishlist({
        variables: {
          appointmentId: appointment.id,
        },
      });
    }
  }, [cancellationFailed, appointment, leaveWishlist]);

  useEffect(() => {
    if (
      statusData?.cancelAppointmentRequest &&
      ["SUCCESS", "FAILED"].includes(statusData.cancelAppointmentRequest.status)
    ) {
      stopPolling();
    }
  }, [stopPolling, statusData]);

  if (error || statusError) {
    throw new Error(t`Something went wrong while processing your request`);
  }

  if (cancellationFailed) {
    throw new Error(
      t`Something went wrong while cancelling your appointment, please try again later`,
    );
  }

  let message = t`You still have your original appointment`;

  if (statusData?.cancelAppointmentRequest?.status === "SUCCESS") {
    message = t`Your appointment has been cancelled`;
  }
  return (
    <Grid
      alignItems="stretch"
      color={colors.zymegoGranite}
      container
      direction="column"
      flexGrow={1}
      sx={{
        padding: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      {loading ||
      statusLoading ||
      (statusData?.cancelAppointmentRequest &&
        ["IN_PROGRESS", "QUEUED"].includes(
          statusData.cancelAppointmentRequest.status,
        )) ? (
        <Grid item>
          <Loading
            logo={false}
            text={t`Please wait while processing your request`}
          />
        </Grid>
      ) : (
        <>
          <Grid item>
            {noCancellationRequest ? (
              <Card
                sx={{
                  border: "2px solid rgba(0 ,0 ,0 , 0.2)",
                  borderRadius: "13px",
                }}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    padding: { xs: 2, sm: 3 },
                  }}
                >
                  <Typography
                    data-cy="your-request-has-been-saved"
                    variant="h5"
                  >
                    <Trans>Your request has been saved!</Trans>
                  </Typography>
                  <Typography>{message}</Typography>
                </Box>
                {appointment ? (
                  <Box
                    sx={{
                      alignContent: "center",
                      borderTop: "2px solid rgba(0 ,0 ,0 ,0.2 )",
                      padding: { xs: 2, sm: 3 },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          ":first-letter": {
                            textTransform: "capitalize",
                          },
                        }}
                        variant="h5"
                      >
                        {formatDate(
                          new Date(appointment.startAtInCareUnitsTimezone),
                        )}
                      </Typography>
                      <Typography>
                        {formatTime(
                          new Date(appointment?.startAtInCareUnitsTimezone),
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{appointment.caregiver?.name}</Typography>
                      {/* <Box sx={{ display: "flex" }}>
                        <LocationOnOutlinedIcon></LocationOnOutlinedIcon>
                        <Typography>map</Typography>
                      </Box> */}
                    </Box>
                  </Box>
                ) : undefined}
                <Box
                  sx={{
                    alignItems: "center",
                    borderTop: "2px solid rgba(0 ,0 ,0 ,0.2 )",
                    padding: { xs: 2, sm: 3 },
                  }}
                >
                  <Typography>
                    <Trans>
                      If a time slot becomes available, we'll send you offers.
                    </Trans>
                  </Typography>
                  <Typography pt={1}>
                    <Trans>First come, first served.</Trans>
                  </Typography>
                </Box>
              </Card>
            ) : (
              <Card
                sx={{
                  border: "2px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "13px",
                }}
              >
                <Box
                  sx={{
                    display: "flex-inline",
                    alignItems: "center",
                    padding: { xs: 2, sm: 3 },
                  }}
                >
                  <Typography marginBottom={2} variant="h4">
                    <Trans>Your request has been saved!</Trans>
                  </Typography>
                  <Typography variant="h5">{message}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "auto",
                    padding: { xs: 2, sm: 3 },
                    borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                    gap: "24px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",

                      color: colors.zymegoWarmGreen,
                      backgroundColor: colors.zymegoSpearMint,
                    }}
                  >
                    <TwoArrows style={{ fill: "currentColor" }} />
                  </Avatar>
                  <Typography variant="subtitle1">
                    <Trans>
                      If a time that suits you becomes available, we will send a
                      booking confirmation.
                    </Trans>
                  </Typography>
                </Box>
              </Card>
            )}
          </Grid>

          <Grid item paddingBottom={3}>
            <MuiButton
              color="secondary"
              component={Link}
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              fullWidth
              to="/"
              variant="contained"
            >
              <Trans>My Appointments</Trans>
            </MuiButton>
            <Button design="transparent" href="/logout" icon="logout">
              <Trans>Sign out</Trans>
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
export { WishListStatus };
