import { t, Trans } from "@lingui/macro";
import { Box, Button, Link as MuiLink, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { trackEvent } from "../analytics";
import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ThumbsUp } from "../icons/thumbs-up.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors } from "../theme";
import { useAddAppointmentToWaitlistMutation } from "./add-appointment-to-waitlist.graphql";
import { useGetCurrentUserAndAppointmentQuery } from "./get-current-user-and-appointment.graphql";
import { useUpdatePatientMutation } from "./update-patient.graphql";

function WaitlistStatusDialog() {
  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const appointmentId = searchParameters.get("appointmentId") ?? "";
  const email = searchParameters.get("email");
  const notificationType = searchParameters.get("notificationType");
  const patientId = searchParameters.get("patientId");
  const phoneNumber = searchParameters.get("phoneNumber");

  invariant(email);
  invariant(notificationType === "EMAIL" || notificationType === "SMS");
  invariant(patientId);

  const { data: { appointment } = {} } = useGetCurrentUserAndAppointmentQuery({
    skip: !appointmentId,
    variables: { appointmentId },
  });

  const [updatePatient, { error: patientMutationError }] =
    useUpdatePatientMutation();

  const [addAppointmentToWaitlist, { error: waitlistMutationError }] =
    useAddAppointmentToWaitlistMutation();

  const patientMutationCalled = useRef(false);
  const waitlistMutationCalled = useRef(false);

  useEffect(() => {
    if (
      appointment &&
      !appointment.onWaitlist &&
      !waitlistMutationCalled.current
    ) {
      waitlistMutationCalled.current = true;
      addAppointmentToWaitlist({
        variables: { appointmentId: appointment.id },
      }).then(() => {
        if (appointment.careUnit) {
          trackEvent("join-waitlist", {
            props: {
              careUnitId: appointment.careUnit.id,
              careUnitName: appointment.careUnit.name,
            },
          });
        }
      });
    }
  }, [addAppointmentToWaitlist, appointment]);

  useEffect(() => {
    if (!patientMutationCalled.current) {
      patientMutationCalled.current = true;
      updatePatient({
        variables: { email, notificationType, patientId, phoneNumber },
      });
    }
  }, [
    email,
    notificationType,
    patientId,
    patientMutationCalled,
    phoneNumber,
    updatePatient,
  ]);

  if (patientMutationError || waitlistMutationError) {
    console.error(patientMutationError ?? waitlistMutationError);
    throw new Error(t`Something went wrong`);
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  return (
    <DialogBottom
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          padding: 3,
          textAlign: "center",
        }}
      >
        <ThumbsUp height={150} />

        <Typography align="center" padding={2} variant="h4">
          <Trans>You will receive offers of earlier appointments</Trans>
        </Typography>
        <Typography
          align="center"
          color={colors.zymegoDarkGreen}
          padding={2}
          sx={{ opacity: "60%" }}
          variant="subtitle2"
        >
          <Trans>
            If a time slot becomes available, we'll send offers to the people
            first in line.
          </Trans>
        </Typography>

        <Button component={Link} fullWidth to="/" variant="contained">
          <Trans>Close</Trans>
        </Button>
      </Box>
    </DialogBottom>
  );
}

export { WaitlistStatusDialog };
