mutation WishListRequest($appointmentId: UUID!, $selectedDates: [Datetime!]!) {
  addAppointmentToWishlist(
    input: { appointmentId: $appointmentId, selectedDates: $selectedDates }
  ) {
    success
    query {
      # patientAppointments is a hack, see comment on app_public.patient_appointments
      appointments: patientAppointments {
        id
        onWishlist
      }
    }
  }
}
