import { Box, Grid } from "@mui/material";

import { colors } from "../../theme";
import { Icon, IconName } from "../icon/icon";

export function IconCircle({
  colorized,
  icon,
}: {
  colorized?: boolean;
  icon: IconName;
}) {
  return (
    <Grid
      item
      sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: colorized ? colors.zymegoSpearMint : "#EFEFEE",
          borderRadius: "100%",
          color: colorized ? colors.zymegoWarmGreen : "#CECECF",
          display: "flex",
          height: "116px",
          justifyContent: "center",
          margin: "8px auto",
          transition: "all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95)",
          width: "116px",
        }}
      >
        <Icon name={icon} size="lg" />
      </Box>
    </Grid>
  );
}
