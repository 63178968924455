import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { isLateAppointmentChange } from "../appointment/appointment-utils";
import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import {
  hasAppointmentExceededRescheduleLimit,
  isWishlistEnabled,
} from "../utils";
import { useCancellationRequestMutation } from "./cancellation-request.graphql";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function CancellationConfirmation() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId: id } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  invariant(id);

  const {
    loading,
    error,
    data: { appointment } = {},
  } = useGetAppointmentQuery({
    variables: { id },
  });

  const navigate = useNavigate();

  const [
    cancellationRequest,
    { loading: mutationLoading, error: mutationError },
  ] = useCancellationRequestMutation();

  const handleCancelRequest = async () => {
    const cancellationReasonRequired =
      appointment?.bookingType?.cancellationReasonRequired || false;
    if (cancellationReasonRequired) {
      navigate(`../reason`, { state: { backgroundLocation } });
    } else {
      const { data: cancellationData } = await cancellationRequest({
        variables: {
          appointmentId: id,
        },
      });
      navigate(
        `../status/${cancellationData?.cancelAppointment?.cancelAppointmentRequest?.id}`,
        { state: { backgroundLocation } },
      );
    }
  };

  const canReschedule =
    !hasAppointmentExceededRescheduleLimit(appointment) &&
    appointment?.canReschedule;
  const isLateCancellation = isLateAppointmentChange(appointment);

  if (error) {
    throw new Error(t`Something went wrong`);
  }
  if (!loading && !appointment) {
    throw new Error(t`We couldn't find your appointment.`);
  }
  if (mutationError) {
    throw new Error(
      t`We couldn't cancel your appointment. Please try again or contact the clinic.`,
    );
  }

  let wishlistEnabled = false;
  if (appointment && appointment.careUnit && appointment.bookingType) {
    const { careUnit, bookingType } = appointment;
    wishlistEnabled = isWishlistEnabled({ careUnit, bookingType });
  }

  return (
    <DialogBottom
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          color={colors.blue}
          onClick={() => window.history.back()}
          underline="none"
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          padding: {
            xs: 1,
            sm: 2,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography align="center" gutterBottom variant="h4">
          <Trans>Are you sure you want to cancel?</Trans>
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          disabled={
            (appointment?.rescheduleAppointmentRequestsByAppointmentId ?? [])
              .length > 0
          }
          fullWidth
          onClick={() => {
            navigate("../../manage", {
              state: { backgroundLocation },
              replace: true,
            });
          }}
          size="large"
          variant="dialog"
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              textAlign: "center",
              margin: "auto",
              gap: "24px",
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",

                color: colors.red,
                backgroundColor: colors.zymegoPowder,
              }}
            >
              <ArrowLeft style={{ fill: "currentColor" }} />
            </Avatar>
            <Trans>No, back</Trans>
          </Box>
        </Button>
      </Box>
      <Box
        sx={{
          padding: {
            xs: 1,
            sm: 2,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <>
          <Box sx={{ padding: 1 }}>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "7px",
                opacity: "60%",
                backgroundColor: colors.zymegoPowder,
                padding: {
                  xs: 1,
                  sm: 2,
                },
                gap: "8px",
              }}
            >
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",

                  backgroundColor: colors.zymegoRose,
                  color: colors.zymegoDarkGreen,
                  fontSize: "large",
                }}
              >
                <PriorityHighIcon sx={{ fontSize: 20 }} />
              </Avatar>

              <Typography variant="h6">
                {canReschedule ? (
                  wishlistEnabled ? (
                    isLateCancellation ? (
                      <Trans>
                        This is a late cancellation. You will still have to pay
                        for this appointment if you cancel.
                      </Trans>
                    ) : (
                      <Trans>
                        Don't cancel if you want another appointment. Go back
                        and follow the instructions instead.
                      </Trans>
                    )
                  ) : (
                    <Trans>
                      Don't cancel if you want a new appointment. Reschedule or
                      call the clinic instead.
                    </Trans>
                  )
                ) : (
                  <Trans>
                    If you want a new appointment please contact your clinic to
                    reschedule.
                  </Trans>
                )}
              </Typography>
            </Box>
          </Box>

          <Button
            color="primary"
            data-cy="confirm-cancellation-button"
            disabled={mutationLoading}
            endIcon={
              theme.direction === "ltr" ? (
                <ArrowRight style={{ fill: "currentColor" }} />
              ) : (
                <ArrowLeft style={{ fill: "currentcolor" }} />
              )
            }
            fullWidth
            onClick={() => {
              handleCancelRequest();
            }}
            size="large"
            variant="contained"
          >
            <Trans>Confirm cancellation</Trans>
          </Button>
        </>
      </Box>
    </DialogBottom>
  );
}

export { CancellationConfirmation };
