query GetTimeslots(
  $fetchTimeslotRequestId: UUID!
  $bookingTypeIds: [UUID!]!
  $careUnitIds: [UUID!]!
  $caregiverIds: [UUID]
  $earliestStartAt: Datetime
  $latestEndAt: Datetime
) {
  timeslots: timeslotsForNewAppointment(
    bookingTypeIds: $bookingTypeIds
    careUnitIds: $careUnitIds
    caregiverIds: $caregiverIds
    earliestStartAt: $earliestStartAt
    latestEndAt: $latestEndAt
  ) {
    id
    careUnit {
      id
      name
      urlFriendlyName
    }
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    endAtInCareUnitsTimezone: visisbleEndAtInCareUnitsTimezone
    caregiver {
      id
      externalIdHash
      name
    }
  }
  fetchTimeslotsRequests(
    filter: { batchId: { equalTo: $fetchTimeslotRequestId } }
  ) {
    id
    status
  }
}
