import { apm } from "@elastic/apm-rum";
import { Trans } from "@lingui/macro";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Layout } from "./layout";

function NotFound() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    apm.captureError({
      name: "Not Found",
      message: `No match for ${location.pathname}`,
    });
  }, [location.pathname]);

  const navigateToHomePage = useCallback(() => navigate("/"), [navigate]);

  return (
    <Layout>
      <Box
        sx={{
          flex: 1,
          padding: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ flex: 0.8, justifyContent: "center" }}>
          <Typography align="center" sx={{ marginBottom: 1 }} variant="h2">
            <Trans>404 - Page Not Found</Trans>
          </Typography>

          <Typography align="center">
            <Trans>
              The requested URL
              <Typography display={"inline"} variant={"boldSubtitle1"}>
                {" " + location.pathname}
              </Typography>
              <Typography>was not found on this server.</Typography>
            </Trans>
          </Typography>
        </Stack>

        <Box
          sx={{
            flex: 0.2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="secondary"
            fullWidth
            onClick={navigateToHomePage}
            variant="contained"
          >
            <Trans>Back to home page</Trans>
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}

export { NotFound };
