query GetAppointment($id: UUID!) {
  appointment: booking(id: $id) {
    bookingType {
      id
      maxRescheduleCount
      cancellationReasonRequired
    }
    canCancel
    canReschedule
    rescheduleCount
    id
    cancelAppointmentRequestsByAppointmentId {
      id
    }
    careUnit {
      id
      enabledFeatures {
        id
        name
      }
    }
    onWaitlist
    onWishlist
    rescheduleAppointmentRequestsByAppointmentId {
      id
    }
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    startAt: visibleStartAt
  }
}
