query GetAppointment($id: UUID!) {
  appointment: booking(id: $id) {
    id
    canCancel
    canReschedule
    bookingType {
      id
      maxRescheduleCount
      useDifferentColorForAlternativeCaregivers
      wishlistEnabled
    }
    rescheduleCount
    onWaitlist
    onWishlist
    startAt: visibleStartAt
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    careUnit {
      id
      name
      enabledFeatures {
        id
        name
      }
      address
      postAddress
      postCode
    }
    caregiver {
      name
      id
    }
  }
}
