mutation NewAppointmentAndUpdatePatient(
  $consentForConfirmationsAndReminders: Boolean
  $timeslotId: UUID!
  $patientId: UUID!
  $reason: String!
  $shortlinkIdent: String
  $email: String!
  $phoneNumber: String
) {
  createAppointment(
    input: {
      timeslotId: $timeslotId
      reason: $reason
      shortlinkIdent: $shortlinkIdent
    }
  ) {
    createAppointmentRequest {
      id
      status
    }
  }

  updatePatient(
    input: {
      id: $patientId
      patch: {
        consentForConfirmationsAndReminders: $consentForConfirmationsAndReminders
        email: $email
        phoneNumber: $phoneNumber
      }
    }
  ) {
    patient {
      id
      consentForConfirmationsAndReminders
      email
      phoneNumber
    }
  }
}
