mutation LeaveWishlist($appointmentId: UUID!) {
  removeAppointmentFromWishlist(input: { appointmentId: $appointmentId }) {
    success
    query {
      # patientAppointments is a hack, see comment on app_public.patient_appointments
      appointments: patientAppointments {
        id
        onWishlist
      }
    }
  }
}
