import { createContext, ReactNode } from "react";
import { Location } from "react-router-dom";

import { useDefinedContext } from "../utils";

const BackgroundLocationContext = createContext<Location | undefined>(
  undefined,
);

function useBackgroundLocation() {
  return useDefinedContext({
    context: BackgroundLocationContext,
    hookName: useBackgroundLocation.name,
    providerName: BackgroundLocationProvider.name,
  });
}

function BackgroundLocationProvider({
  backgroundLocation,
  children,
}: {
  backgroundLocation: Location;
  children: ReactNode;
}) {
  return (
    <BackgroundLocationContext.Provider value={backgroundLocation}>
      {children}
    </BackgroundLocationContext.Provider>
  );
}

export { BackgroundLocationProvider, useBackgroundLocation };
