import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Radio } from "../components/radio/radio";
import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function CancellationAnotherAppointment() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId: id } = useParams();

  // TODO: Use react-hook-form instead
  const [anotherAppointment, setAnotherAppointment] = useState<string>("");

  invariant(id);
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const navigate = useNavigate();

  const { loading, data: { appointment } = {} } = useGetAppointmentQuery({
    variables: { id },
  });

  // XXX: Why not just inline this function?
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnotherAppointment(event.target.value);
  };

  const disabled = loading || anotherAppointment === "";
  const isOtherAppointmentDeclined = anotherAppointment === "No";

  let to = "";

  if (isOtherAppointmentDeclined) {
    to = appointment?.bookingType?.cancellationReasonRequired
      ? "../reason"
      : "../confirm";
  } else if (anotherAppointment === "Yes") {
    to = "../cancellation-to-wishlist";
  }

  return (
    <DialogBottom
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() =>
            navigate("../../cancel", {
              state: { backgroundLocation },
              replace: true,
            })
          }
          style={{
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            lineHeight: "18px",
          }}
          underline="none"
        >
          {theme.direction === "ltr" ? (
            <ArrowBackIos fontSize="small" />
          ) : (
            <ArrowForwardIos fontSize="small" />
          )}
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          paddingY: { xs: 1, sm: 2 },
          paddingX: {
            xs: 3,
            sm: 4,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography textAlign="center" variant="h5">
          <Trans>Would you like another appointment at the clinic?</Trans>
        </Typography>
      </Box>
      <Radio.Group
        onChange={handleChange}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
        value={anotherAppointment}
      >
        <Radio
          data-cy="cancellation-another-appointment-yes"
          label={t`Yes`}
          sx={{ borderRight: "0.5px solid rgba(0, 0, 0, 0.1)", paddingY: 2.5 }}
          value="Yes"
        />
        <Radio
          data-cy="cancellation-another-appointment-no"
          label={t`No`}
          sx={{ borderLeft: "0.5px solid rgba(0, 0, 0, 0.1)", paddingY: 2.5 }}
          value="No"
        />
      </Radio.Group>
      <Box sx={{ paddingX: 3, paddingTop: 2 }}>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: isOtherAppointmentDeclined
              ? `${colors.zymegoPowder}40`
              : undefined,
            borderRadius: 3,
            color: colors.zymegoDarkGreen,
            display: "inline-flex",
            gap: 3,
            justifyContent: "center",
            paddingX: 3,
            paddingY: 1.5,
            transition: theme.transitions.create("background-color"),
          }}
        >
          {isOtherAppointmentDeclined && (
            <Avatar
              sx={{
                backgroundColor: colors.zymegoPowder,
                color: "inherit",
                fontSize: "large",
                height: "30px",
                width: "30px",
              }}
            >
              <PriorityHighIcon sx={{ fontSize: 20 }} />
            </Avatar>
          )}
          {isOtherAppointmentDeclined ? (
            <Typography variant="h6">
              <Trans>
                If you want another appointment you should select "Yes".
              </Trans>
            </Typography>
          ) : (
            <Typography
              sx={{ opacity: "60%", textAlign: "center" }}
              variant="h6"
            >
              <Trans>Choose whether you want another appointment or not</Trans>
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: {
            xs: 2,
            sm: 3,
          },
          paddingY: {
            xs: 1,
            sm: 2,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          color="primary"
          component={Link}
          data-cy="cancellation-another-appointment-proceed-to-cancel-button"
          disabled={disabled}
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight style={{ fill: "currentColor" }} />
            ) : (
              <ArrowLeft style={{ fill: "currentcolor" }} />
            )
          }
          fullWidth
          size="large"
          state={{ backgroundLocation }}
          to={to}
          variant="contained"
        >
          <Trans>Proceed to cancel</Trans>
        </Button>
      </Box>
    </DialogBottom>
  );
}
export { CancellationAnotherAppointment };
