import { ApmRoutes } from "@elastic/apm-rum-react";
import { Route } from "react-router-dom";

import { NhsSignInPage } from "../nhs/sign-in-page";
import { staticUrls } from "../urls";

export function PublicApp() {
  return (
    <ApmRoutes>
      <Route
        element={<NhsSignInPage />}
        path={staticUrls.bookings.new.nhsLogin}
      />
    </ApmRoutes>
  );
}
