import { i18n } from "@lingui/core";
import { Locale, setDefaultOptions } from "date-fns";
import { ar, cy, enUS as en, sv } from "date-fns/locale";

const dateFnsLocaleMap: Record<string, Locale> = { ar, cy, en, sv };

setDefaultOptions({
  locale: dateFnsLocaleMap[i18n.locale],
});

function setupDateFnsLocale(locale?: string): void {
  setDefaultOptions({
    locale: locale ? dateFnsLocaleMap[locale] : undefined,
  });
}

export { setupDateFnsLocale };
