import CheckIcon from "@mui/icons-material/Check";
import { Box, Grid } from "@mui/material";

import { colors } from "../../theme";

export function Stepper({
  isValid,
  stepIndex,
  stepsCount,
}: {
  isValid: boolean;
  stepIndex: number;
  stepsCount: number;
}) {
  return (
    <Grid
      item
      sx={{
        alignItems: "center",
        display: "flex",
        gap: "33px",
        justifyContent: "center",
      }}
    >
      {/* eslint-disable-next-line max-params */}
      {Array.from({ length: stepsCount }).map((_, index) => (
        <Box
          key={index}
          sx={{
            alignItems: "center",
            backgroundColor:
              index === stepIndex ? colors.zymegoWarmGreen : "#C4C4C4",
            borderRadius: "100%",
            color:
              index === stepIndex
                ? isValid
                  ? colors.white
                  : colors.zymegoWarmGreen
                : "transparent",
            display: "flex",
            height: "22px",
            justifyContent: "center",
            transform:
              index === stepIndex && isValid ? "scale(1)" : "scale(0.5)",
            transition: "all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95)",
            width: "22px",
          }}
        >
          <CheckIcon sx={{ fontSize: "16px" }} />
        </Box>
      ))}
    </Grid>
  );
}
