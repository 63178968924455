import { t } from "@lingui/macro";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { AuthLevel, useAuthLevel } from "../nhs/user-auth-level";
import { getNextOnboardingStep } from "../onboarding/onboarding-view";
import { getConfig } from "../runtime-config";
import { useUrls } from "../urls";
import { AppUserState } from "../user/app-user";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";

const { AUTH_IDENTITY_PROVIDER } = getConfig();

function PrivateRouteLogIn({
  requiredAuthLevel,
}: {
  requiredAuthLevel: AuthLevel;
}) {
  const { loading: isAuthLevelLoading, satisfiesAuthLevel } = useAuthLevel();
  const location = useLocation();
  const [searchParameters] = useSearchParams();
  const urls = useUrls();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  if (loading || !patient) {
    return null;
  }

  if (error) {
    throw new Error(t`Something went wrong`);
  }

  if (getNextOnboardingStep(patient)) {
    return <Navigate state={location} to="onboarding" />;
  }

  if (
    AUTH_IDENTITY_PROVIDER === "nhs" &&
    !isAuthLevelLoading &&
    !satisfiesAuthLevel(requiredAuthLevel)
  ) {
    // We need to include the `clinicName` query parameter in order to
    // show the NHS upgrade view for the new booking flow:
    const clinicName = searchParameters.get("clinicName");
    const state: AppUserState | undefined = clinicName
      ? {
          locationState: {
            pathname: urls.bookings.new.index,
            search: `?clinicName=${clinicName}`,
            state: globalThis.history.state,
          },
        }
      : undefined;
    return <Navigate state={state} to={urls.nhsUpgrade} />;
  }

  return <Outlet />;
}

export { PrivateRouteLogIn };
