import { Trans } from "@lingui/macro";
import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { isLateAppointmentChange } from "../../appointment/appointment-utils";
import { useDateFormatter } from "../../datetime/use-date-formatter";
import { ReactComponent as ArrowLeft } from "../../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { ReactComponent as Clock } from "../../icons/clock.svg";
import { MessageList } from "../../messages/message-list";
import { colors, theme } from "../../theme";
import { GetAppointmentAndOfferListQuery } from "../get-appointments-and-offers-list.graphql";

function AppointmentComponent({
  appointment,
}: {
  appointment: NonNullable<
    GetAppointmentAndOfferListQuery["appointments"]
  >[number];

  onClick?: () => void;
}) {
  const { formatDate, formatTime } = useDateFormatter();
  const location = useLocation();

  const canManage = appointment.canCancel || appointment.canReschedule;
  const isLateAppointment = isLateAppointmentChange(appointment);

  return (
    <Grid data-cy="appointment-list-item" item key={appointment.id}>
      <Card
        sx={{
          boxShadow: "0px 16px 16px -11px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          sx={{
            padding: {
              xs: "12px 16px",
              sm: "24px 24px 8px 24px",
            },
          }}
        >
          <Typography
            component="div"
            sx={{
              fontWeight: 400,
            }}
            variant="h4"
          >
            {appointment.careUnit?.name}
          </Typography>
          <Typography
            component="div"
            sx={{
              fontWeight: 700,
            }}
            variant="subtitle1"
          >
            {appointment.bookingType?.visibleName ? (
              <Trans>
                {appointment.bookingType.visibleName} with{" "}
                {appointment.caregiver?.name}
              </Trans>
            ) : (
              <Trans>Appointment with {appointment.caregiver?.name}</Trans>
            )}
          </Typography>
          <Typography
            component="div"
            sx={{
              fontWeight: 400,
              marginTop: 0.5,
              marginBottom: 1,
              ":first-letter": {
                textTransform: "uppercase",
              },
            }}
            variant="h2"
          >
            {formatDate(new Date(appointment.startAtInCareUnitsTimezone))}
          </Typography>

          <Typography
            component="div"
            sx={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              lineHeight: "2rem",
              gap: "11px",
            }}
            variant="h5"
          >
            <Clock
              style={{
                fill: "currentColor",

                fontSize: "1.25rem",
              }}
            />
            <span>
              {formatTime(new Date(appointment.startAtInCareUnitsTimezone))}
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            padding: {
              xs: "12px 16px",
              sm: "12px 24px",
            },
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                component="div"
                sx={{
                  fontWeight: 700,
                  lineHeight: "21px",
                }}
                variant="subtitle2"
              >
                {appointment.careUnit?.name}
                <br />
                {appointment.careUnit?.address &&
                  ` ${appointment.careUnit.address}, ${appointment.careUnit.postCode} ${appointment.careUnit.postAddress}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {canManage && (
          <Box
            sx={{
              padding: {
                xs: "12px 16px",
                sm: "12px 24px",
              },
              ...((appointment.onWaitlist || appointment.onWishlist) && {
                backgroundColor: colors.zymegoLightGreen,
              }),
            }}
          >
            {appointment.onWaitlist || appointment.onWishlist ? (
              <Grid
                alignItems="center"
                container
                direction="row"
                flexWrap="nowrap"
                gap="5px"
                justifyContent="center"
              >
                <Avatar
                  sx={{
                    width: "15px",
                    height: "15px",
                    color: colors.white,
                    backgroundColor: colors.zymegoWarmGreen,
                  }}
                >
                  <CheckIcon sx={{ fontSize: 9 }} />
                </Avatar>
                <Typography variant="subtitle2">
                  {appointment.onWaitlist ? (
                    <Trans>
                      You will receive offers of earlier appointments
                    </Trans>
                  ) : (
                    <Trans>
                      You will receive offers of time slots according to your
                      request
                    </Trans>
                  )}
                </Typography>
              </Grid>
            ) : null}
            <Button
              color="primary"
              component={RouterLink}
              data-cy="manage-appointment-button"
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              fullWidth
              state={{ backgroundLocation: location }}
              to={
                isLateAppointment
                  ? `appointment/${appointment.id}/confirm-late-appointment`
                  : `appointment/${appointment.id}/manage`
              }
              variant="contained"
            >
              <Trans>Manage booking</Trans>
            </Button>

            <Typography
              align="center"
              component="div"
              sx={{
                color: "rgba(34, 76, 79, 0.4)",
                textTransform: "none",
                fontWeight: 700,
              }}
              variant="caption"
            >
              {appointment.onWishlist ? (
                <>
                  <Trans>Don't want another appointment?</Trans>{" "}
                  <Link
                    color={colors.zymegoGranite}
                    component={RouterLink}
                    state={{ backgroundLocation: location }}
                    to={`appointment/${appointment.id}/wishlist/leave`}
                  >
                    <Trans>Click here</Trans>
                  </Link>
                </>
              ) : appointment.onWaitlist ? (
                <>
                  <Trans>Don't want an earlier appointment?</Trans>{" "}
                  <Link
                    color={colors.zymegoGranite}
                    component={RouterLink}
                    state={{ backgroundLocation: location }}
                    to={`appointment/${appointment.id}/waitlist/leave`}
                  >
                    <Trans>Click here</Trans>
                  </Link>
                </>
              ) : (
                <Trans>Reschedule, cancel or get an earlier time</Trans>
              )}
            </Typography>
          </Box>
        )}

        {!canManage && (
          <Alert severity="info">
            <AlertTitle>
              <Trans>
                This booking cannot be managed digitally, according to the
                clinic's rules.
              </Trans>
            </AlertTitle>
            <Trans>
              This may be because of how treatment and follow-up appointments
              are planned or because of scheduling complexity. Contact your
              clinic to reschedule or cancel.
            </Trans>
          </Alert>
        )}

        <MessageList
          messages={[
            ...(appointment.bookingType?.messages ?? []),
            ...(appointment.careUnit?.messages ?? []),
          ]}
        />
      </Card>
    </Grid>
  );
}

export { AppointmentComponent };
