import { Geography } from "../geographies";
import { getConfig } from "../runtime-config";

/**
 * @example
 * ```ts
 * formatPatientIdentifier("196408233234", { geography: "SE" });
 * // "19640823-3234"
 *
 * formatPatientIdentifier("4857773456", { geography: "UK" });
 * // "485 777 3456"
 *
 * formatPatientIdentifier("4857773456", { geography: "UK", maskIdentifier: true });
 * // "*** *** 3456"
 * ```
 */
const formatPatientIdentifier = (
  identifier: string | undefined,
  options?: { geography?: Geography; maskIdentifier?: boolean },
  // eslint-disable-next-line max-params
): string => {
  const { geography = getConfig().GEOGRAPHY } = options ?? {};

  switch (geography) {
    case "SE": {
      const digits = identifier
        ? identifier.replace(/\D/g, "")
        : "*".repeat(12);
      const maskedDigits = options?.maskIdentifier
        ? digits.replace(/\d/g, "*")
        : digits;
      return `${maskedDigits.slice(0, -4)}-${maskedDigits.slice(-4)}`;
    }
    case "UK": {
      const digits = identifier
        ? identifier.replace(/\D/g, "")
        : "*".repeat(10);
      const maskedDigits = options?.maskIdentifier
        ? `${digits.slice(0, -4).replace(/\d/g, "*")}${digits.slice(-4)}`
        : digits;
      return [
        maskedDigits.slice(0, 3),
        maskedDigits.slice(3, 6),
        maskedDigits.slice(6),
      ].join(" ");
    }
  }
};

export { formatPatientIdentifier };
