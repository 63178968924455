import { User } from "oidc-client-ts";

import { LocationState } from "../router/types";
import { userManager } from "../user-manager";

type AppUser = User & {
  readonly state?: AppUserState;
};

type AppUserPayload = ConstructorParameters<typeof User>[0] & {
  userState?: AppUserState;
};

type AppUserState = {
  locationState?: LocationState;
};

function createUser(user: AppUserPayload): AppUser {
  return new User(user) as AppUser;
}

async function getUser(): Promise<AppUser | undefined> {
  const user = await userManager.getUser();
  return user as AppUser | undefined;
}

function getUserStateFromLocalStorage(
  stateKey: string,
): AppUserState | undefined {
  const stateString = globalThis.localStorage.getItem(`oidc.${stateKey}`);
  try {
    return stateString ? JSON.parse(stateString).data : undefined;
  } catch {
    return undefined;
  }
}

export { createUser, getUser, getUserStateFromLocalStorage };
export type { AppUser, AppUserPayload, AppUserState };
