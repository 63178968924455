query GetCancelAppointmentStatus($cancelAppointmentRequestId: UUID!) {
  cancelAppointmentRequest(id: $cancelAppointmentRequestId) {
    id
    careUnitId
    status
    careUnit {
      id
      name
    }
  }
  # patientAppointments is a hack, see comment on app_public.patient_appointments
  appointments: patientAppointments {
    id
  }
}
