mutation UpdateOfferStatus($id: UUID!, $status: OffersStatus!) {
  updateOffer(input: { id: $id, patch: { status: $status } }) {
    offer {
      id
      status
      rescheduleAppointmentRequestId
      timeslotId
      appointmentId
    }
  }
}
