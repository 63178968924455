import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { ReactComponent as TwoArrows } from "../icons/two-arrows.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { hasAppointmentExceededRescheduleLimit } from "../utils";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function CancellationToWishlist() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId: id } = useParams();

  invariant(id);
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const {
    data: { appointment } = {},
    loading,
    error,
  } = useGetAppointmentQuery({
    variables: {
      id,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    throw new Error(t`Something went wrong while fetching your appointment.`);
  }

  const hasNotExceededRescheduleLimit =
    !hasAppointmentExceededRescheduleLimit(appointment);

  return (
    <DialogBottom
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() =>
            navigate("../another-appointment", {
              state: { backgroundLocation },
              replace: true,
            })
          }
          style={{
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            lineHeight: "18px",
          }}
          underline="none"
        >
          {theme.direction === "ltr" ? (
            <ArrowBackIos fontSize="small" />
          ) : (
            <ArrowForwardIos fontSize="small" />
          )}
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          paddingY: { xs: 1, sm: 2 },
          paddingX: {
            xs: 3,
            sm: 4,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography textAlign="center" variant="h5">
          <Trans>
            Before you cancel, you need to request days for your new appointment
          </Trans>
        </Typography>
      </Box>

      {hasNotExceededRescheduleLimit ? (
        <Box
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Button
            component={Link}
            disabled={
              (appointment?.rescheduleAppointmentRequestsByAppointmentId ?? [])
                .length > 0
            }
            fullWidth
            size="large"
            to="../../reschedule"
            variant="dialog"
          >
            <Box
              sx={{
                width: "auto",
                margin: "auto",
                display: "inline-flex",
                alignItems: "center",
                gap: "24px",
                justifyContent: "flex-start",
              }}
            >
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",

                  color: colors.zymegoWarmGreen,
                  backgroundColor: colors.zymegoSpearMint,
                }}
              >
                <TwoArrows style={{ fill: "currentColor" }} />
              </Avatar>

              <Trans>I want to reschedule instead</Trans>
            </Box>
          </Button>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              paddingX: { sx: 2, sm: 3 },
            }}
          >
            <Typography
              fontSize="22px"
              fontWeight="700"
              pb={2}
              textAlign="center"
            >
              <Trans>
                You have reached the limit for maximum nr of reschedules
              </Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              paddingX: { sx: 1, sm: 2 },
              marginBottom: { sx: 1, sm: 2 },
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "7px",
                background: "rgba(248,244,231,0.2)",
                backgroundColor: colors.zymegoLightTravertine,
                padding: {
                  xs: 1,
                  sm: 2,
                },
              }}
            >
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",
                  marginRight: theme.direction === "ltr" ? "8px" : "0px",
                  marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                  backgroundColor: colors.zymegoTravertine,
                  color: colors.zymegoDarkGreen,
                  fontSize: "large",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "50%",
                }}
              >
                <PriorityHighIcon sx={{ fontSize: 20 }} />
              </Avatar>

              <Typography
                align="left"
                color={colors.zymegoDarkGreen}
                fontSize="17px"
                fontWeight="600"
                sx={{
                  background: "rgba(0, 0, 0, 0)",
                }}
              >
                <Trans>
                  According to the clinic's rules, you may reschedule a{" "}
                  <span style={{ fontSize: "17px", fontWeight: "700" }}>
                    maximum
                  </span>{" "}
                  of{" "}
                  <span style={{ fontSize: "17px", fontWeight: "700" }}>
                    {appointment?.bookingType?.maxRescheduleCount}
                  </span>{" "}
                  times. If you have any questions, contact your clinic.
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box sx={{ padding: 1 }}>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: 3,
            opacity: "60%",
            backgroundColor: colors.zymegoPowder,
            padding: {
              xs: 1,
              sm: 2,
            },
            gap: 1,
          }}
        >
          <Avatar
            sx={{
              width: "30px",
              height: "30px",

              backgroundColor: colors.zymegoRose,
              color: colors.zymegoDarkGreen,
              fontSize: "large",
            }}
          >
            <PriorityHighIcon sx={{ fontSize: 20 }} />
          </Avatar>

          <Typography variant="h6">
            <Trans>
              You need to request days before the cancellation goes through
            </Trans>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          paddingX: {
            xs: 2,
            sm: 3,
          },
          paddingY: {
            xs: 1,
            sm: 2,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          color="primary"
          component={Link}
          data-cy="cancellation-another-appointment-request-days-button"
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight style={{ fill: "currentColor" }} />
            ) : (
              <ArrowLeft style={{ fill: "currentcolor" }} />
            )
          }
          fullWidth
          replace
          size="large"
          state={{
            backgroundLocation: `/appointment/${id}/wishlist`,
          }}
          to={"../../wishlist/welcome"}
          variant="contained"
        >
          <Trans>Request days</Trans>
        </Button>
      </Box>
    </DialogBottom>
  );
}
export { CancellationToWishlist };
