import { Trans } from "@lingui/macro";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Alert, AlertTitle, Avatar, Button, Grid, Link } from "@mui/material";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { colors } from "../theme";
import {
  deepLinkWithProvidedBookingTypeId,
  deepLinkWithProvidedCaregiverId,
} from "../utils";

function NewBookingError({
  headerTextMessage,
  bodyTextMessage,
}: {
  headerTextMessage?: string;
  bodyTextMessage?: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParameters] = useSearchParams();
  const careUnitName = searchParameters.get("clinicName"); //FIXME: how do we render and error here for multiple care units?
  const bookingTypeId = searchParameters.get("bookingTypeId");
  const caregiverId = searchParameters.get("caregiverId");
  const shortlinkIdent = searchParameters.get("link");

  const handleRefreshButton = () => {
    let queryParameters = `clinicName=${careUnitName}`;

    if (deepLinkWithProvidedBookingTypeId(location)) {
      queryParameters = `${queryParameters}&bookingTypeId=${bookingTypeId}`;
    }

    if (deepLinkWithProvidedCaregiverId(location)) {
      queryParameters = `${queryParameters}&caregiverId=${caregiverId}`;
    }

    if (shortlinkIdent) {
      queryParameters = `${queryParameters}&link=${shortlinkIdent}`;
    }

    navigate({
      pathname: "/bookings/new",
      search: `${queryParameters}`,
    });
  };

  return (
    <Grid
      alignItems="stretch"
      color={colors.zymegoGreen}
      container
      direction="column"
      flexGrow={1}
      sx={{
        paddingX: 3,
      }}
      textAlign="center"
    >
      <Grid item>
        <Alert
          icon={
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: colors.zymegoPowder,
                color: colors.zymegoDarkGreen,
              }}
            >
              <PriorityHighIcon sx={{ fontSize: 20 }} />
            </Avatar>
          }
          sx={{
            backgroundColor: colors.zymegoPowderLight,

            font: "boldSubtitle1",
            paddingX: 2,
          }}
        >
          <AlertTitle
            sx={{
              paddingX: 1,
              textAlign: "center",
              fontSize: "17px",
              fontWeight: "700",
            }}
          >
            {headerTextMessage ?? <Trans>Something went wrong</Trans>}
          </AlertTitle>

          <AlertTitle
            sx={{
              paddingX: 1,
              textAlign: "start",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {bodyTextMessage ?? (
              <Trans>
                We are experiencing a temporary technical issue. Please click
                the button to go back to the starting page and try again. If it
                still doesn’t work, please{" "}
                <Link
                  component={RouterLink}
                  state={{ backgroundLocation: location }}
                  to="/contact"
                >
                  contact us
                </Link>
                .
              </Trans>
            )}
          </AlertTitle>
        </Alert>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          fullWidth
          onClick={handleRefreshButton}
          variant="contained"
        >
          <Trans>Back to start</Trans>
        </Button>
      </Grid>
    </Grid>
  );
}

export { NewBookingError };
