query GetCurrentPatient {
  patient: currentPatient {
    id
    consentForConfirmationsAndReminders
    email
    notificationType
    phoneNumber
    consent
  }
}
