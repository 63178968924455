mutation RescheduleAppointmentAndUpdatePatient(
  $appointmentId: UUID!
  $timeslotId: UUID!
  $patientId: UUID!
  $patient: PatientPatch!
) {
  rescheduleAppointment(
    input: { appointmentId: $appointmentId, timeslotId: $timeslotId }
  ) {
    rescheduleAppointmentRequest {
      id
      status
    }
  }

  updatePatient(input: { id: $patientId, patch: $patient }) {
    patient {
      consentForConfirmationsAndReminders
      email
      id
      notificationType
      phoneNumber
    }
  }
}
