query GetTimeslots($appointmentId: UUID!, $fetchTimeslotRequestId: UUID!) {
  appointment: booking(id: $appointmentId) {
    id
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
  }
  timeslots: timeslotsForAppointment(appointmentId: $appointmentId) {
    id
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    caregiver {
      name
      id
    }
  }
  fetchTimeslotsRequest(id: $fetchTimeslotRequestId) {
    id
    status
  }
}
