mutation CreateProfile(
  $patientIdentifier: String!
  $careUnitUrlFriendlyName: String
) {
  createProfile(
    input: {
      patientIdentifier: $patientIdentifier
      careUnitUrlFriendlyName: $careUnitUrlFriendlyName
    }
  ) {
    validateAndCreateGuardianSessionRequest {
      id
      status
    }
  }
}
