import { Trans } from "@lingui/macro";
import { SxProps, Typography } from "@mui/material";

export function NhsContactInfoUpdateText({ sx }: { sx: SxProps }) {
  return (
    <Typography sx={{ textAlign: "center", ...sx }} variant="body2">
      <Trans>
        Updating your contact information will not affect your NHS account
        information.
      </Trans>
    </Typography>
  );
}
