import { AuthLevel } from "./user-auth-level";

type NhsIdentityProofingLevel = "P0" | "P5" | "P9";

/**
 * @see https://nhsconnect.github.io/nhslogin/vectors-of-trust/
 */
type NhsVectorOfTrustValue =
  | `P${0 | 5 | 9}.C${"m" | "p"}`
  | `P${0 | 5 | 9}.Cp.C${"d" | "k"}`;

/**
 * @see https://nhsconnect.github.io/nhslogin/vectors-of-trust/
 */
const vectorsOfTrust: Record<
  NhsIdentityProofingLevel,
  NhsVectorOfTrustValue[]
> = {
  P0: ["P0.Cp"],
  P5: ["P5.Cp.Cd", "P5.Cp.Ck"],
  P9: ["P9.Cp.Cd", "P9.Cp.Ck", "P9.Cm"],
};

const DEFAULT_VECTORS_OF_TRUST = vectorsOfTrust.P5;

const getNhsIdentityProofingLevel = (
  authLevel: AuthLevel,
): NhsIdentityProofingLevel => {
  switch (authLevel) {
    case "low":
      return "P0";
    case "medium":
      return "P5";
    case "high":
      return "P9";
  }
};

export {
  DEFAULT_VECTORS_OF_TRUST,
  getNhsIdentityProofingLevel,
  vectorsOfTrust,
};
export type { NhsIdentityProofingLevel, NhsVectorOfTrustValue };
