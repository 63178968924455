query GetCareUnitsWithBookingTypes($careUnitUrlFriendlyNames: [String!]!) {
  careUnits(filter: { urlFriendlyName: { in: $careUnitUrlFriendlyNames } }) {
    id
    externalHomePageUrl
    name
    bookingTypes {
      id
      externalIdHash
      canBookNew
      name
      visibleName
      isRevisit
    }
  }
}
