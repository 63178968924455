import { t, Trans } from "@lingui/macro";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { isLateAppointmentChange } from "../appointment/appointment-utils";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useGetAppointmentAndTimeslotQuery } from "./get-appointment-and-timeslot.graphql";

function RescheduleConfirmationDialog() {
  const backgroundLocation = useBackgroundLocation();
  const { formatDateTime } = useDateFormatter();
  const { appointmentId, timeslotId } = useParams();

  invariant(appointmentId);
  invariant(timeslotId);

  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const {
    data: { appointment, timeslot } = {},
    error,
    loading,
  } = useGetAppointmentAndTimeslotQuery({
    variables: { appointmentId, timeslotId },
  });

  function handleDialogClose() {
    setIsDialogOpen(false);
  }
  if (error) {
    throw new Error(t`Something went wrong`);
  }

  if (!loading && (!appointment || !timeslot)) {
    throw new Error(t`We couldn't find your appointment.`);
  }

  const isLateReschedule = isLateAppointmentChange(appointment);

  return (
    <DialogBottom
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          justifyContent: "center",
          padding: 2,
          paddingBottom: 4,
          paddingTop: 0,
        }}
      >
        <Box>
          <Typography align="center" marginBottom={2} variant="h4">
            <Trans>You are rescheduling your appointment</Trans>
          </Typography>
          {isLateReschedule ? (
            <Alert
              severity="error"
              sx={{
                marginBottom: {
                  xs: 1,
                  sm: 2,
                },
                borderRadius: "8px",
              }}
            >
              <AlertTitle>
                <Trans>
                  This is a late reschedule. You will still have to pay for this
                  appointment if you cancel.
                </Trans>
              </AlertTitle>
            </Alert>
          ) : undefined}
          {timeslot ? (
            <Box paddingBottom={2}>
              <Typography
                align="center"
                color="secondary"
                data-cy="reschedule-confirm-time"
                sx={{
                  ":first-letter": {
                    textTransform: "uppercase",
                  },
                }}
                variant="h5"
              >
                {formatDateTime(new Date(timeslot.startAtInCareUnitsTimezone))}
              </Typography>
              <Typography
                align="center"
                color="secondary"
                data-cy="reschedule-confirm-caregiver"
                variant="h5"
              >
                <Trans>with {timeslot.caregiver?.name}</Trans>
              </Typography>
            </Box>
          ) : undefined}
        </Box>
        <Button
          color="primary"
          component={Link}
          data-cy="reschedule-confirm-next"
          disabled={loading}
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight style={{ fill: "currentColor" }} />
            ) : (
              <ArrowLeft style={{ fill: "currentcolor" }} />
            )
          }
          fullWidth
          state={{ backgroundLocation }}
          to="../contact-info"
          variant="contained"
        >
          <Trans>Next</Trans>
        </Button>
        <Typography
          align="center"
          color={colors.zymegoDarkGreen}
          fontSize="16px"
          fontWeight="700"
          marginBottom={2}
          sx={{
            opacity: "60%",
            padding: "0 16px",
          }}
        >
          <Trans>
            When you reschedule, your current appointment will be replaced.
          </Trans>
        </Typography>
      </Box>
    </DialogBottom>
  );
}

export { RescheduleConfirmationDialog };
