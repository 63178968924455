query GetAppointment($id: UUID!) {
  appointment: booking(id: $id) {
    id
    bookingType {
      id
      maxRescheduleCount
    }
    rescheduleCount
    careUnit {
      id
      name
      address
      postCode
      postAddress
      enabledFeatures {
        id
        name
      }
    }
    caregiver {
      name
      id
    }
    startAtInCareUnitsTimezone: visibleStartAtInCareUnitsTimezone
    onWaitlist
    canCancel
    canReschedule
    rescheduleAppointmentRequestsByAppointmentId(
      filter: { status: { in: [QUEUED, IN_PROGRESS] } }
    ) {
      id
      status
    }
    cancelAppointmentRequestsByAppointmentId(
      filter: { status: { in: [QUEUED, IN_PROGRESS] } }
    ) {
      id
      status
    }
  }
}
