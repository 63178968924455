mutation AddAppointmentToWaitlist($appointmentId: UUID!) {
  addAppointmentToWaitlist(input: { appointmentId: $appointmentId }) {
    success
    query {
      # patientAppointments is a hack, see comment on app_public.patient_appointments
      appointments: patientAppointments {
        id
        onWaitlist
      }
    }
  }
}
