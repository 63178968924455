import { t, Trans } from "@lingui/macro";
import { Grid, Typography } from "@mui/material";

import { ErrorComponent } from "../../error";
import { Loading } from "../../loading";
import { useGetOffersQuery } from "./get-offers.graphql";
import { OfferDetails } from "./offer";
import { RevokedOffer } from "./revoked-offer";

function OfferList() {
  const { data, loading, error } = useGetOffersQuery({
    fetchPolicy: "network-only",
    pollInterval: 5 * 1000,
  });

  const activeOffers = data?.offers?.filter(
    (offer) => offer.status === "UNDECIDED",
  );

  //eslint-disable-next-line max-params
  const sortedActiveOffers = [...(activeOffers ?? [])].sort(
    // eslint-disable-next-line max-params
    (a, b) => {
      if (a.timeslot && b.timeslot) {
        return (
          new Date(a.timeslot?.startAt).getTime() -
          new Date(b.timeslot?.startAt).getTime()
        );
      }
      return 0;
    },
  );

  if (error) {
    return (
      <ErrorComponent
        errorMessage={t`Something went wrong while fetching your offers.`}
      />
    );
  }

  if (loading) {
    return <Loading text={t`Please wait while we are fetching your offers.`} />;
  }

  return activeOffers?.length ? (
    <Grid sx={{ padding: { sm: 2, xs: 1 }, justifyContent: "center" }}>
      <Typography
        component="div"
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          fontWeight: 700,
          letterSpacing: "0.166em",
          paddingBottom: {
            xs: 1,
            sm: 2,
          },
        }}
        variant="caption"
      >
        <Trans>New offer</Trans>
      </Typography>

      {sortedActiveOffers?.map((offer) => (
        <Grid item key={offer.id}>
          <OfferDetails offer={offer} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Grid
      alignItems="stretch"
      container
      direction="column"
      flexGrow={1}
      justifyContent="space-between"
      sx={{
        padding: {
          xs: 1,
          sm: 2,
        },
      }}
    >
      <Typography
        component="div"
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          fontWeight: 700,
          letterSpacing: "0.166em",
          paddingBottom: {
            xs: 2,
            sm: 3,
          },
        }}
        variant="caption"
      >
        <Trans>No offers</Trans>
      </Typography>
      <RevokedOffer />
    </Grid>
  );
}
export { OfferList };
