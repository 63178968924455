query GetOffers {
  offers {
    id
    status
    rescheduleAppointmentRequestId
    timeslot {
      id
      caregiver {
        id
        name
      }
      careUnit {
        name
        id
      }
      startAt
      endAt
      startAtInCareUnitsTimezone
      endAtInCareUnitsTimezone
    }
    appointment {
      id
      startAt
      endAt
      startAtInCareUnitsTimezone
      endAtInCareUnitsTimezone
    }
  }
}
