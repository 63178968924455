mutation ContactZymego(
  $email: String!
  $message: String!
  $careUnitName: String
) {
  contactZymego(
    input: { email: $email, message: $message, careUnitName: $careUnitName }
  ) {
    success
  }
}
