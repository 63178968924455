import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";

import { AuthShield } from "../auth/auth-shield";
import { Button } from "../components/button/button";
import { Layout } from "../layout";
import { getConfig } from "../runtime-config";
import { colors } from "../theme";
import { useUrls } from "../urls";
import { AppUserState } from "../user/app-user";
import { AuthLevel } from "./user-auth-level";

const { AUTH_IDENTITY_PROVIDER } = getConfig();

function NhsUpgradeView({
  onBackClick,
  requiredAuthLevel,
}: {
  onBackClick: () => void;
  requiredAuthLevel: AuthLevel;
}) {
  const location = useLocation();
  const urls = useUrls();

  if (AUTH_IDENTITY_PROVIDER !== "nhs") {
    return <Navigate to={urls.index} />;
  }

  const state = location.state as AppUserState;

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Typography
          component="div"
          sx={{
            color: colors.zymegoGranite,
            fontWeight: 700,
            letterSpacing: "0.166em",
            textAlign: "center",
            textTransform: "uppercase",
          }}
          variant="caption"
        >
          Upgrade your NHS account
        </Typography>
        <AuthShield
          onBackClick={onBackClick}
          requiredAuthLevel={requiredAuthLevel}
          state={state}
        />
        <Button design="transparent" href={urls.logout} icon="logout">
          <Trans>Sign out</Trans>
        </Button>
      </Box>
    </Layout>
  );
}

export { NhsUpgradeView };
