import { Trans } from "@lingui/macro";
import { Box, Button, Typography } from "@mui/material";

import { DialogBottom } from "../dialog-bottom";
import { InfoBox } from "../info-box/info-box";

interface RevisitInfoDialogProperties {
  isOpen: boolean;
  onConfirm: () => void;
}

function RevisitInfoDialog({ isOpen, onConfirm }: RevisitInfoDialogProperties) {
  return (
    <DialogBottom open={isOpen}>
      <Box
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          padding: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={1}
          sx={{ padding: { xs: 1, sm: 2 } }}
          textAlign="center"
        >
          <Typography gutterBottom variant="h4">
            <Trans>Select caregiver</Trans>
          </Typography>
          <Typography variant="info">
            <Trans>
              On return visits, it is important that you choose the same
              caregiver as on your previous visit. If the caregiver is not in
              the list, contact your clinic.
            </Trans>
          </Typography>
        </Box>
        <InfoBox>
          <Trans>
            A different caregiver might not know your history and treatment
            plan.
          </Trans>
        </InfoBox>
        <Box
          sx={{
            paddingX: {
              xs: 1,
              sm: 2,
            },
          }}
        >
          <Button
            fullWidth
            onClick={onConfirm}
            type="button"
            variant="contained"
          >
            <Trans>Select caregiver</Trans>
          </Button>
        </Box>
      </Box>
    </DialogBottom>
  );
}

export { RevisitInfoDialog };
